export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const logo = require("../assets/logo.svg");
export const bookingProfilePic = require("../assets/booking_profile_pic.svg");
export const rescheduleLogo = require("../assets/reschedule.svg");
export const confirmCheckLogo = require("../assets/confirm_check.svg");
export const prev = require("../assets/prev.png");
export const forward = require("../assets/forward.png");

export const location = require("../assets/location.svg");
export const timer = require("../assets/timer.svg");
