import React from "react";

// Customizable Area Start
import { Box, Typography, Button, IconButton, TextField, Checkbox } from "@mui/material";
import { styled } from "@mui/styles";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useGoogleLogin, TokenResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleIcon, LoginImage, imageLogo } from "./assets";

interface GoogleLoginButtonProps {
    onSuccess: (tokenResponse: TokenResponse) => void;
    onError: () => void;
}

const StyledBox = styled(Box)({
    width:"50%",
    height:"900px",
    position:"relative",
    "@media (max-width: 768px)": {
        display:"none"
      },
})
const StyledDiv = styled(Box)({
     padding:"40px",
     maxWidth:  "440px",
     width:"100%",
    "@media (max-width: 457px)": {
       width:"100%",
       padding:"0px",
      },
})
const StyledMainDiv = styled(Box)({
    display:"flex",
    justifyContent:"space-around",
   "@media (max-width: 457px)": {
     backgroundColor:"#F1F5F9",
     justifyContent:"center",
     },
})

const StyledWrapper = styled(Box)({
    width:"50%",
    display: "flex",
    justifyContent: "center",
   "@media (max-width: 457px)": {
    width:"87%",
     },
})


// Customizable Area End

import EmailAccountLoginController, {
  Props,
  configJSON,
} from "./EmailAccountLoginController";

export default class LoginPage extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(){
     let data = localStorage.getItem("rememberMe")
     if(data){
         let parsedData =JSON.parse(data)
          this.setState({rememberMe: parsedData.remember})
     }
  }
  renderAlert = () => {
    setTimeout(()=>{
        this.setState({errorStatus: false, error: true})
    },3000)
    return <Box style={{marginBottom:"20px", width:"360px", display:"flex", justifyContent:"center", alignItems:"center" ,height:"42px", borderLeft:"4px solid #DC2626", borderRadius:"4px", background: "#FEE2E2"}}>
    <Typography style={{color:"#DC2626",fontFamily: "Inter",fontSize: "12px",fontWeight: 400,lineHeight: "18px",textAlign: "left",
    }}>{this.state.responseMessage}</Typography>
    </Box>
  }

  // Customizable Area End
  // Customizable Area Start
  render() {
    let validation = (this.state.passwordValue && this.state.loginValue) ? true : false
    return (
        <StyledMainDiv data-test-id= "LoginPage" style={{ overflowX: "hidden" }}>
            <StyledWrapper>
            <div style={{display: "flex", width: "100%", maxWidth: "440px"}}>
            <StyledDiv>
        <Typography style={webStyles.Typo}>Log in</Typography>
            {this.state.errorStatus && this.renderAlert()}
        <Box style={{display:"flex", flexDirection:"column"}}>
        <Typography style={webStyles.commonTypo}>Email</Typography>
        <TextField type="text"
         style={{...webStyles.selectBox, marginBottom: `${this.state.loginValidationError ? "0px" : "20px" }` }}
         value={this.state.loginValue}
         data-test-id= "loginId"
         onBlur={this.handleValidation}
         onChange={this.handleChange}
         name="email" 
         placeholder="Your email"  />
         {!this.state.loginValue && <Typography style={{color:"#DC2626",fontFamily: "Inter",fontSize: "12px",fontWeight: 400,lineHeight: "18px"}}>{this.state.loginValidationError}</Typography>}
        <Typography style={webStyles.commonTypo}>Password</Typography>
        <Box>
            <TextField
             style={{...webStyles.selectBox,marginBottom:"5px"}} 
             placeholder="Your password" 
             name="password"
             data-test-id= "passwordId"
             value={this.state.passwordValue}
             onBlur={this.handleValidation}
             onChange={this.handleChange}
             type={this.state.showPassword ? 'text' : 'password'}
             InputProps={{endAdornment: <IconButton
             data-test-id = "iconId"
                onClick={this.handleShowPassword}
                style={{ position: 'absolute', right: 0 }}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff /> }
              </IconButton>}}
            />
            {!this.state.passwordValue && <Typography style={{color:"#DC2626",fontFamily: "Inter",fontSize: "12px",fontWeight: 400,lineHeight: "18px"}}>{this.state.passwordValidationError}</Typography>}
      </Box>
        <Box style={{display:"flex", justifyContent: `${this.state.error ? "space-between" : "flex-end"  }`}}>
            {this.state.error && <Box style={{display:"flex", gap:"10px"}}>
            <Checkbox  
              data-test-id="checkBoxID"
              checked={this.state.rememberMe}
              onChange={this.handleRemember.bind(this)}
              style={{border: "1px solid #CBD5E1", height:"20px", width:"20px", borderRadius:"6px"}}
              /> 
            <Typography style={webStyles.signupMsg}>Remember me</Typography> 
             </Box>}
        <Typography style={webStyles.forgotText} data-test-id= "navigateid2" onClick={this.handleNavigation.bind(this,"ForgotPasswordWeb")}>Forgot Password?</Typography>
        </Box>
        <Box style={{display:"flex", flexDirection:"column"}}>
        <Button style={{...webStyles.selectBox, backgroundColor:"#5E70DD", color:"#FFFFFF",
         fontWeight:"700", fontSize:"16px", lineHeight:"24px", fontFamily:"Inter", textTransform:"initial"}}
          data-test-id= "checkID" onClick={()=> this.loginApi(validation) }>Log in</Button>
         <Box style={{marginBottom:"20px"}} >
         <span style={webStyles.signupMsg}>Don’t have an account? <span style={webStyles.forgotText} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign up</span></span>
         </Box>
         <Box style={{display:"flex",justifyContent:"center"}}>
         <Typography style={webStyles.forgotText}>or</Typography>
         </Box>
         <GoogleOAuthProvider clientId={configJSON.googleClientId}>
          <GoogleLoginButton 
            onSuccess={this.handleSuccess.bind(this)} 
            onError={this.handleError.bind(this)} 
          />
        </GoogleOAuthProvider>
        </Box>
        </Box>
           </StyledDiv>
            </div>
            </StyledWrapper>
           
           <StyledBox>
            <img src={LoginImage.default} style={{ width:"100%"}} />
            <img src={imageLogo.default} style={{ width:"152px", height: "114px", position: "absolute", top: "46px", right: "38%"}}/>
           </StyledBox>

        </StyledMainDiv>
    )
  }
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onSuccess, onError }) => {
  const googleLogin = useGoogleLogin({
      onSuccess,
      onError,
  });
  return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box style={{
              ...webStyles.selectBox, maxWidth: "312px", width: "100%", height: "48px", display: "flex",
              gap: "50px", paddingLeft: "10px", alignItems: "center", cursor: "pointer"
          }}
              onClick={() => googleLogin()}
          >
              <img src={GoogleIcon.default} style={{ height: "26px", width: "26px" }} />
              <Typography style={webStyles.googleLogintext}>Login with Google</Typography>
          </Box>
      </Box>
  );
};
  // Customizable Area End
export const webStyles = {
  // Customizable Area Start
    Typo:{
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.11px",
        textAlign: "left" as "left",
        marginBottom:"20px"
    },
    commonTypo: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left"
    },
    selectBox: {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        marginBottom:"20px"
        
    },
    forgotText: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left" as "left",
        color:"#5E70DD",
        marginBottom:"20px"

    },
    googleLogintext: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#00000099"
    },
    signupMsg:{
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        marginBottom:"20px"
    }
  // Customizable Area End
}
