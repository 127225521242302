import React from "react";

// Customizable Area Start
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { LoginImage, imageLogo } from "./assets";


const StyledBox = styled(Box)({
    height:"900px",
    width:"50%",
    position:"relative",
    "@media (max-width: 768px)": {
        display:"none"
      },
})
const StyledMainDiv = styled(Box)({
    justifyContent:"space-around",
    overflowX: "hidden",
    display:"flex",
   "@media (max-width: 457px)": {
       justifyContent:"center",
     backgroundColor:"#F1F5F9",
     },
})



// Customizable Area End

import ForgotPasswordLoginController, {
  Props,
} from "./ForgotPasswordController";
export default class ForgotPassword extends ForgotPasswordLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderForgotPasswordScreen = () => {
    return <Box style={{width:"50%", paddingTop:"40px", height:"740px", paddingBottom:"40px", display:"flex",justifyContent:"center",alignItems: "center"}}>
        <Box style={{width:"440px",height:"505px", paddingTop:"40px", paddingBottom:"40px"}}>
        <Typography style={{fontFamily: "Inter",fontSize: "24px",fontWeight: 700,lineHeight: "32px",
            letterSpacing: "-0.11px",textAlign: "left", marginBottom:"30px"}}>Password recovery</Typography>
            {!this.state.mailSend && 
            <Box style={{width:"80%"}}>
        <Typography style={webStyles.forgotTypo}>Forgot password?</Typography>
        <Typography style={webStyles.text}>No worries, we'll send a recovery link to your email.</Typography>
        <Typography style={{...webStyles.commonTypo, marginBottom:"5px"}}>Email</Typography>
        <Box style={{marginBottom:"20px"}}>
        <TextField 
        style={{width: "360px", height: "56px",  borderRadius: "8px"}}
        placeholder="Your Email"
        name="email"
        value={this.state.loginValue}
        data-test-id= "forgotId"
        onChange={this.handleChange}
        />
        </Box>
        <Box style={{marginBottom:"20px"}}>
        <Button style={{width: "360px",height: "56px", borderRadius:"8px", backgroundColor:"#5E70DD"}}
        data-test-id= "btnClickID"
         onClick={()=> this.setState({mailSend: true})}>
            <Typography style={{fontFamily: "Inter",fontSize: "16px",fontWeight: 700,lineHeight: "24px",
                textAlign: "left", color:"white", textTransform:"initial"
                }}>Send a recovery link</Typography>
            </Button>
            </Box>
            <Typography style={{fontFamily: "Inter",fontSize: "16px",fontWeight: 400,
            lineHeight: "24px",textAlign: "left", marginBottom:"15px"}}>Back to <span style={webStyles.spanText}  onClick={this.handleNavigation.bind(this,"LoginPage")}>Login</span></Typography>
                </Box>}
               { this.state.mailSend &&
                <Box style={{width:"80%"}}>
                    <Typography style={webStyles.forgotTypo} >Check your email</Typography>
                    <Typography style={webStyles.text}>We have sent a password reset link to email@email.com</Typography>
                    <Box style={{display:"flex", justifyContent:"space-between"}}>
                        <Typography style={webStyles.spanText} >Resend e-mail</Typography>

                        <Typography style={{fontFamily: "Inter",fontSize: "16px",fontWeight: 400,
            lineHeight: "24px",textAlign: "left", marginBottom:"15px"}}>Back to <span style={webStyles.spanText} data-test-id="navigateId" onClick={()=> this.handleNavigation("LoginPage")}>Login</span></Typography>
                    </Box>
                </Box> }
    </Box>
    </Box>
  }

  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
        <StyledMainDiv>
           {this.renderForgotPasswordScreen()}
           <StyledBox>
            <img src={LoginImage.default} style={{width:"100%"}} />
            <img src={imageLogo.default} style={{ width:"152px", height: "114px", position: "absolute", top: "46px", right: "38%"}}/>

           </StyledBox>

        </StyledMainDiv>
    )
  }
  // Customizable Area End
}

export const webStyles = {
  // Customizable Area Start
    commonTypo: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left"
    },
    text:{
       fontFamily: "Inter",
       fontSize: "16px",
       fontWeight: 400,
       lineHeight: "24px",
       textAlign: "left" as "left",
       marginBottom:"15px"
               
    },
    forgotTypo: {
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "26px",
            textAlign: "left" as "left",
            marginBottom:"10px"
    },
    spanText: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left" as "left",
        color:"#5E70DD" 
    }
  // Customizable Area End
}
