import React from "react";

// Customizable Area Start
import { Box,Typography, Modal, Button, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/styles"
import { arrowIcon, Logo, Close } from "./assets"

// Customizable Area End
// Customizable Area Start
const StyledBox = styled(Box)({
        display:"flex", 
        width:"100%",
        maxWidth: "1290px",
        height: "78px",
        padding: "0px 124px 0px 124px",
        justifyContent: "space-between", 
        backgroundColor: "#D2D2D2",
 "@media (max-width: 768px)": {
        display:"none"
   },
})

const AlignDiv = styled(Box)({
  marginLeft:"60px",
"@media (max-width: 768px)": {
  width:"80%"
},
})
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  renderDeleteModal = () => {
    return  <Box style={{
      maxWidth: "600px",
      width:"100%",
      maxHeight: "450px",
      height:"100%",
      borderRadius: "10px",
      backgroundColor:"#FFFFFF",
    }}>
      <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
      <img src={Close.default} onClick={this.closeModal}/>
     </Box>
     <Box style={{display: "flex", flexDirection: "column", gap: "40px", maxHeight: "426px",  height: "100%"}}>
     <Box style={{width:"92%", padding: "26px"}}>
     <Box>
      <Typography style={webStyles.logTitle}>Are you sure you want to delete your account?</Typography>
      <Typography style={webStyles.logText}>This action can’t be undone and all the data stored in your account won’t be accessible anymore.</Typography>
     </Box>
     <Box>
     <Box style={{marginTop:"16px"}}>
        <Typography style={webStyles.passText}>Enter your password</Typography>
        <input
        type="text"
        name="oldPass"
        data-test-id = "PassId"
        value = {this.state.oldPassword}
        onChange={(event)=> this.handleText(event)}
        style={webStyles.textBox}
        />
        </Box>
        </Box>
     </Box>
        <Box style={{border:"1px solid #E2E8F0", width:"99.5%"}}></Box>
        <Box>
          <Box style={{display:"flex", justifyContent:"flex-end", paddingRight: "20px", alignItems: "center"}}>
            <Box style={{display:"flex",gap:"16px"}}>
            <Button style={webStyles.cancelBtn} onClick={this.closeModal}>Cancel</Button>
            <Button style={{...webStyles.btn,width:"154.95px",padding:"0px"}} onClick = {this.deleteAccount}>Delete account</Button>
            </Box>
          </Box>
        </Box>
        </Box>
     
     </Box>
  }

  renderLogOutModal = () => {
    return <Box style={{
      maxWidth: "600px",
      width:"100%",
      maxHeight: "252px",
      height:"100%",
      borderRadius: "10px",
      backgroundColor:"#FFFFFF",
    }}>
      <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
      <img src={Close.default} onClick={this.closeModal}/>
     </Box>
     <Box style={{  display: "flex", flexDirection: "column", gap: "40px",  height: "100%", maxHeight: "228px"}}>
      <Box style={{paddingLeft:"26px", paddingRight:"26px",}}>
      <Typography style={{...webStyles.logTitle, width: "540px"}} >Are you sure you want to log out of your account?</Typography>
      </Box>
      <Box style={{border:"1px solid #E2E8F0", width:"99.5%"}}></Box>
      <Box>
      <Box style={{display:"flex", justifyContent:"flex-end", paddingRight: "20px"}}>
            <Box style={{display:"flex",gap:"16px"}}>
            <Button style={{...webStyles.cancelBtn,width:"143.88px"}} onClick={this.closeModal}>Cancel</Button>
            <Button style={{...webStyles.btn,width:"143.88px",padding:"0px"}} onClick={this.logout}>Log out</Button>
            </Box>
          </Box>
      </Box>
      </Box>
     </Box>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ overflowX: "hidden" }}>            
      <StyledBox>
          <Box style={{display:"flex", gap:"48px", justifyContent:"center", alignItems:"center"}}>
              <img src={Logo.default} />
              
              <Typography style={{fontFamily: "Epilogue", fontSize: "16px", fontWeight: 500, lineHeight: "25.6px", color:"#515B6F" }}>Find Jobs</Typography>
          </Box>
          <Box>
              <Select
              defaultValue={1}
               sx={webStyles.styledData}
               style={{...webStyles.headerText, maxWidth:"197px",width:"100%",height:"78px",backgroundColor:"#D2D2D2", border:"none"}}
              >
                  <MenuItem key={1} value={1}> David Jones</MenuItem>
                  <MenuItem>option2</MenuItem>
              </Select>
          </Box>
      </StyledBox>
      {(this.state.currentTab === 1) && 
      <AlignDiv>
      <Box>
        <Typography style={webStyles.profileText}>{configJSON.myProfileText}</Typography>
      </Box>
      <Box style={{marginTop:"20px",maxWidth:"1220px", width:"100%", height:"37px",borderBottom:"1px solid #D6DDEB"}}>
        <Box style={{display:"flex", gap:"40px"}}>
            <Typography data-test-id = "txtInput" onClick={()=> this.setState({currentTab: 1 })}
            style={{ fontFamily: "Manrope",fontSize: "16px",color: "#686F7E" ,fontWeight: 600, lineHeight: "25.6px"}}>Overview</Typography>
            <Typography data-test-id = "txtInput2" onClick={()=> this.setState({currentTab: 1 })}
             style={{ fontFamily: "Manrope",fontSize: "16px",color: "#25324B" ,fontWeight: 600, lineHeight: "25.6px"}}>Settings</Typography>
          
        </Box>
          <Box style={{ marginLeft:`${(this.state.currentTab === 1) ? "106px" : "0px"}`,width:"60px", height:"4px",backgroundColor:"#5E70DD",borderRadius:"4px",marginTop:"10.8px"}}> </Box>
      </Box>
          <Box style={{marginTop: "10px"}}>

          <Box style={webStyles.boxWidth}>
            <Typography style={webStyles.commonText}>Change Password</Typography>
            <img src={arrowIcon.default} 
             onClick={this.openModal}
                style={{transform: 'rotate(272deg)'}} 
                />
          </Box>
          <Box style={webStyles.boxWidth}>
            <Typography style={webStyles.commonText}>Terms and Conditions</Typography>
            <img src={arrowIcon.default} onClick={ this.goTermsConditions } 
                style={{transform: 'rotate(272deg)'}} 
                />
          </Box>
          <Box style={webStyles.boxWidth}>
            <Typography style={{...webStyles.commonText, color:"#F40000"}}>Delete Account</Typography>
            <img src={arrowIcon.default} 
                style={{transform: 'rotate(272deg)'}} 
                onClick={this.openDeleteModal.bind(this,"DELETE")}
                />
          </Box>

          <Box style={webStyles.boxWidth}>
            <Typography style={{...webStyles.commonText, color:"#F40000"}}>Log Out</Typography>
            <img src={arrowIcon.default} onClick={this.openDeleteModal.bind(this,"LOGOUT")}
                style={{transform: 'rotate(272deg)',}} 
                />
          </Box>
          </Box>
      </AlignDiv> }
      <Modal
      open={this.state.isOpen}
      onClose={this.closeModal}
      style={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <Box style={{
          maxWidth: "600px",
          width:"100%",
          maxHeight: "450px",
          height:"100%",
          borderRadius: "10px",
          backgroundColor:"#FFFFFF",
        }}>
          <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
          <img src={Close.default} onClick={this.closeModal}/>
         </Box>
          <Box style={{
             display: "flex",
             flexDirection: "column",
              gap: "24px",
             padding: "20px"
          }}>
            <Typography style={webStyles.changePass}>Change password</Typography>
            <Typography >You can update your password to keep your account secure.</Typography>
            <Box>
              <Box>
              <Typography style={webStyles.passText}>Old password</Typography>
              <input
              type="text"
              name="oldPass"
              data-test-id = "oldPassId"
              value = {this.state.oldPassword}
              onChange={(event)=> this.handleText(event)}
              style={webStyles.textBox}
              />
              </Box>
              <Box style={{marginTop: "10px"}}>
              <Typography style={webStyles.passText}>New password</Typography>
              <input
              type="text"
              name="newPass"
              data-test-id = "newPassId"
              onChange={(event)=> this.handleText(event)}
              value = {this.state.newPassword}
              style={webStyles.textBox}
              />
              </Box>
            </Box>
          </Box>
          <Box>

          </Box>
        <Box style={{border:"1px solid #E2E8F0", width:"99.5%", marginTop:"24px"}}></Box>
        <Box style={{paddingRight:"24px", display: "flex", justifyContent:"flex-end", paddingTop:"40px"}}>
          <Button style={webStyles.btn} onClick={this.resetPasswordApi}>Change password</Button>

        </Box>
        </Box>

      </Modal>

      <Modal
      open={this.state.deleteModal}
      onClose={this.closeModal}
      style={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <>
         {this.state.renderStatus === "DELETE" && this.renderDeleteModal()}
         {this.state.renderStatus === "LOGOUT" && this.renderLogOutModal()}
        </>

         </Modal>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const webStyles = {
commonText: {
  fontFamily: "Manrope",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.10px",
  color: "#000000"
},
changePass: {
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.11px",
  color: "#0F172A"
},
text: {
  fontFamily: "Manrope",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#0F172A"
},
textBox: {
  maxWidth: "520px",
  width: "100%",
  height: "100%",
  maxHeight: "44px",
  padding: "10px 8px 10px 8px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1"
},
  btn: {
  width: "205px",
  height: "43px",
  padding: "12px 24px 12px 24px",
  borderRadius: "10px",
  color: "#FFFFFF",
  backgroundColor: "#5E70DD",
  textTransform: "capitalize" as "capitalize",
  fontFamily: "Manrope",
  fontSize: "15px",
  fontWeight: 700,
  lineHeight: "25.6px"

},
 boxWidth: {
  display:"flex",
  padding:"15px",
  justifyContent:"space-between",
  maxWidth:"1193px",
  borderBottom:"1px solid #E5E5E5",
  width:"100%"
},
  passText: {
  fontFamily: "Manrope",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  color: "#334155"
 },
headerText: {
  width: "366px",
    height: "46px",
    borderRadius: "19px",
    backgroundColor: "#F4F4F4",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px"
},
cancelBtn: {
  width:"154.95px",
  height: "43px",
  padding: "12px 24px 12px 24px",
  borderRadius: "10px",
  border: "1px solid #5E70DD",
  color: "#5E70DD",
  textTransform: "capitalize" as "capitalize",
  fontFamily: "Manrope",
  fontWeight: 700
},
logTitle:{
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.11px",
  color: "#0F172A",
  width: "520px"

},
logText: {
  fontFamily: "Manrope",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#0F172A",
  marginTop:"16px"
},
profileText: {
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "28.8px",
  marginTop:"32px",
  color:"#25324B"
},
styledData: {
  '& .MuiSelect-select': {
    border: 'none', // Remove border from select
  },
  '& fieldset': {
    border: 'none', // Remove border from the fieldset
  },
 '& .MuiMenuItem-root': {
   backgroundColor:"white"
 },
 '& .MuiMenuItem-root.Mui-selected': {
   backgroundColor: 'white', // Change background color when selected
 },
 '& .MuiMenuItem-root:hover': {
   backgroundColor: '#b0bec5', // Change background color on hover
 },
},
}



// Customizable Area End
