import React from "react";
import { StyleSheet } from "react-native";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
    SelectChangeEvent,
    IconButton,
    Checkbox,
    Card,
    Modal,
    InputAdornment
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UserProfileBasicController, {
    Props,
} from "./UserProfileBasicController";

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SearchIcon from '@mui/icons-material/Search';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
interface Item {
    heading: string;
    subHeading: string;
    checked: boolean;
}
const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const configJSON = require("./config");
import {
    logo, bookingProfilePic, rescheduleLogo, confirmCheckLogo,
    prev, forward, location, timer
} from "./assets"
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';


export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);

    }
    customToolbar = (toolbar: any) => {
        const date = moment(toolbar.date).format('MMMM, YYYY');

        return (
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <Box>

                    <span data-testID="prevId" onClick={() => toolbar.onNavigate('PREV')} style={{
                        marginLeft: "10px", color: "#003861", fontFamily: "Manrope", fontSize: "17.98px",
                        fontWeight: 700, lineHeight: "22.48px"
                    }}>{`< `} </span>
                    <span style={{
                        marginLeft: "10px", color: "#003861", fontFamily: "Manrope", fontSize: "17.98px",
                        fontWeight: 700, lineHeight: "22.48px"
                    }}>{date}</span>
                    <span onClick={() => toolbar.onNavigate('NEXT')} style={{
                        marginLeft: "10px", color: "#003861", fontFamily: "Manrope", fontSize: "17.98px",
                        fontWeight: 700, lineHeight: "22.48px"
                    }}>{` >`}</span>
                </Box>

                <span style={{
                    marginLeft: "10px", color: "#548CFF", fontFamily: "Roboto", fontSize: "16px",
                    fontWeight: 600, lineHeight: "18.75px"
                }}>Edit</span>
            </div>
        );
    };

    renderWorkPreferencesAndInterests() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                <Typography style={{
                    width: "fit-content", height: "34px", color: "#25324B", fontFamily: "Manrope",
                    fontSize: "28px", fontWeight: 400
                }}>
                    What are you good at?
                </Typography>
                <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    {
                        configJSON.workPreferencesAndInterestsData.map((lable: Item, keyData: number) => (
                            <Box style={{
                                display: "flex", gap: "10px",
                                alignItems: "center", justifyContent: "flex-start"
                            }}>
                                <Checkbox
                                    data-test-id="workCheckboxTestBtn"
                                    checked={this.handleChecks(keyData)}
                                    onChange={(event) => this.handleWnpCheckbox(event, keyData)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: "#5E70DD"
                                        },
                                    }}
                                    style={{ border: "1px solid #D1D1D6", height: "20px", width: "20px", borderRadius: "4px" }}
                                />
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", color: "#252729", fontFamily: "Manrope",
                                        fontSize: "16px", fontWeight: 600
                                    }}>
                                        {lable.heading}
                                    </Typography>
                                    <Typography style={{
                                        width: "fit-content", color: "#818486", fontFamily: "Manrope",
                                        fontSize: "12px", fontWeight: 400
                                    }}>
                                        {lable.subHeading}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        )
    }

    renderBookings() {
        const { openCancelPopup } = this.state;
        const openCancelBtn = Boolean(openCancelPopup);

        const { openReceiptPopup } = this.state;
        const openReceiptBtn = Boolean(openReceiptPopup);

        dayjs.extend(updateLocale);
        dayjs.updateLocale('en', {
            weekStart: 1,
        });

        return (
            <>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "20px" }}>
                    <Box style={{ display: "flex", gap: "40px", alignItems: "center" }}>
                        <Typography style={{
                            width: "fit-content", height: "26px",
                            fontFamily: "Manrope", fontSize: "16px", fontWeight: 600,
                            cursor: "pointer", color: `${this.handleColorAllBookings()}`,
                        }}
                            data-test-id="allBookingAndPendingRequestTestBtn1"
                            onClick={() => this.handleAllBookingAndPendingRequest("All Bookings")}
                        >
                            All Bookings
                        </Typography>
                        <Typography style={{
                            fontSize: "16px", fontWeight: 600, fontFamily: "Manrope",
                            width: "fit-content", height: "26px",
                            cursor: "pointer", color: `${this.handleColorPendingReq()}`
                        }}
                            data-test-id="allBookingAndPendingRequestTestBtn2"
                            onClick={() => this.handleAllBookingAndPendingRequest(this.tabsInBooking())}
                        >
                            {this.tabsInBooking()}
                        </Typography>
                    </Box>
                    {this.state.openAllBookingsOrPendingRequest == "All Bookings" &&
                        this.state.userType == "Hire A Teen" && (
                            <Box style={{ display: "flex", gap: "15px" }}>
                                <Select
                                    data-test-id="bookingStateDropTestBtn"
                                    variant="standard"
                                    value={this.state.bookingStateDropdown}
                                    style={{
                                        width: "183px", height: "40px", borderRadius: "8px",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        fontSize: "15px", color: "#161C2D", outline: "none",
                                        justifyContent: "center", padding: "20px 15px",
                                        cursor: "pointer"
                                    }}
                                    onChange={(event) => this.handleBookingStateDropdown(event)}
                                    disableUnderline
                                    displayEmpty
                                >
                                    {!this.state.bookingStateDropdown && (
                                        <MenuItem value="">
                                            <em>Booking State</em>
                                        </MenuItem>
                                    )}
                                    <MenuItem value={"Upcoming"}>
                                        {"Upcoming"}
                                    </MenuItem>
                                    <MenuItem value={"Pending"}>
                                        {"Pending"}
                                    </MenuItem>
                                    <MenuItem value={"Past"}>
                                        {"Past"}
                                    </MenuItem>
                                    <MenuItem value={"Canceled"}>
                                        {"Canceled"}
                                    </MenuItem>
                                </Select>
                                <Select
                                    variant="standard"
                                    data-test-id="serviceStateDropTestBtn"
                                    style={{
                                        fontSize: "15px", color: "#161C2D", outline: "none",
                                        cursor: "pointer",
                                        width: "183px", height: "40px", borderRadius: "8px",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        justifyContent: "center", padding: "20px 15px",
                                    }}
                                    value={"Service"}
                                    disableUnderline
                                >
                                    <MenuItem value={"Service"} >
                                        {"Service"}
                                    </MenuItem>
                                </Select>
                                <Select
                                    data-test-id="teenStateDropTestBtn"
                                    value={"Teen"}
                                    style={{
                                        width: "183px", height: "40px", borderRadius: "8px",
                                        fontSize: "15px", color: "#161C2D", outline: "none",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        cursor: "pointer",
                                        justifyContent: "center", padding: "20px 15px",
                                    }}
                                    variant="standard"
                                    disableUnderline
                                >
                                    <MenuItem value={"Teen"} >
                                        {"Teen"}
                                    </MenuItem>
                                </Select>
                            </Box>
                        )}
                    {this.state.openAllBookingsOrPendingRequest == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                fontSize: "24px", color: "#101928", fontFamily: "Manrope",
                                width: "fit-content", height: "33px", fontWeight: 600,
                            }}>
                                Upcoming
                            </Typography>
                            <Box style={{
                                display: "flex", borderRight: "2px solid #5E70DD",
                                height: "106px", width: "100%", borderRadius: "8px",
                                justifyContent: " space-between"
                            }}>
                                <Box style={{ display: "flex", gap: "20px" }}>
                                    <Box style={{ display: "flex", padding: "12px", gap: "12px" }}>
                                        <img
                                            src={bookingProfilePic.default}
                                            style={{
                                                width: "78px", height: "71px",
                                                borderRadius: "8px"
                                            }}
                                            alt="profile_pic"
                                        />
                                        <Box style={{
                                            flexDirection: "column",
                                            display: "flex", justifyContent: "center",
                                        }}>
                                            <Typography style={{
                                                fontSize: "10px", fontWeight: 400,
                                                height: "21px",
                                                fontFamily: "Manrope", width: "fit-content", color: "#11141A",
                                            }}>
                                                Sun 17 July 2023 at 5:00pm
                                            </Typography>
                                            <Typography style={{
                                                width: "fit-content", height: "22px",
                                                fontSize: "16px", fontFamily: "Manrope",
                                                color: "#11141A", fontWeight: 700,
                                            }}>
                                                Jack Tohas
                                            </Typography>
                                            <Typography style={{
                                                fontFamily: "Manrope",
                                                width: "fit-content", height: "21px", fontWeight: 400,
                                                fontSize: "14px", color: "#11141A"
                                            }}>
                                                Tech Help
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {this.state.userType != "Hire A Teen" && (
                                        <Box style={{
                                            flexDirection: "column", gap: "6px", alignItems: "start",
                                            display: "flex", justifyContent: "center",
                                        }}>
                                            <Box style={{ display: "flex", alignItems: "center", gap: "8px", }}>
                                                <LocationOnIcon style={{ width: "15px", height: "15px", }} />
                                                <Typography style={{
                                                    color: "#161C2D", fontWeight: 400,
                                                    fontSize: "14px", width: "fit-content",
                                                    fontFamily: "Manrope",
                                                }}>
                                                    3891 Ranchview Dr. Richardson, California 62639
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", gap: "8px", }}>
                                                <img
                                                    src={timer.default}
                                                    style={{ height: "15px", width: "15px" }}
                                                />
                                                <Typography style={{
                                                    width: "fit-content", height: "21px",
                                                    fontSize: "15px",
                                                    color: "#161C2D", fontWeight: 400,
                                                }}>
                                                    Once
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box style={{
                                    display: "flex", justifyContent: "space-evenly",
                                    alignItems: "center", paddingRight: "20px", gap: "20px"
                                }}>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "35px" }}>
                                        <Box style={{
                                            display: "flex", flexDirection: "column",
                                            gap: "2px", alignItems: "center"
                                        }}>
                                            <IconButton style={{
                                                height: "60px", width: "60px", borderRadius: "10px",
                                                padding: "12px 24px", background: "#F7F8FA"
                                            }}>
                                                <EmailOutlinedIcon style={{ color: "#11141A" }} />
                                            </IconButton>
                                            <Typography style={{
                                                width: "fit-content", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                                fontSize: "12px", fontWeight: 400
                                            }}>
                                                Message
                                            </Typography>
                                        </Box>
                                        {this.rescheduleIcon1()}
                                        {this.cancelIcon1()}
                                        {this.declineIcon1()}
                                    </Box>
                                    <Box style={{
                                        display: "flex", background: "#2EC114", alignItems: "center",
                                        height: "30px", width: "120px", borderRadius: "30px",
                                    }}>
                                        <IconButton>
                                            <img
                                                src={confirmCheckLogo.default}
                                                alt="reschedule"
                                                style={{ height: "21px", width: "21px" }}
                                            />
                                        </IconButton>
                                        <Typography style={{
                                            width: "fit-content", height: "21px", color: "#FFFFFF", fontFamily: "Manrope",
                                            fontSize: "12px", fontWeight: 600, textAlign: "center"
                                        }}>
                                            Confirmed
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {this.state.openAllBookingsOrPendingRequest == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                width: "fit-content", height: "33px", fontFamily: "Manrope",
                                fontSize: "24px", fontWeight: 600, color: "#101928",
                            }}>
                                {this.pendingOrReceivedRequests()}
                            </Typography>
                            <Box style={{
                                display: "flex", borderRight: "2px solid #5E70DD",
                                height: "106px", width: "100%", borderRadius: "8px",
                                justifyContent: "space-between",
                            }}>
                                <Box style={{ display: "flex", gap: "20px" }}>
                                    <Box style={{ display: "flex", padding: "12px", gap: "12px" }}>
                                        <img
                                            src={bookingProfilePic.default}
                                            style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                            alt="profile_pic"
                                        />
                                        <Box style={{
                                            flexDirection: "column",
                                            display: "flex", justifyContent: "center",
                                        }}>
                                            <Typography style={{
                                                fontSize: "10px", fontWeight: 400,
                                                fontFamily: "Manrope", height: "21px",
                                                width: "fit-content", color: "#11141A",
                                            }}>
                                                Sun 17 July 2023 at 5:00pm
                                            </Typography>
                                            <Typography style={{
                                                fontSize: "16px", fontFamily: "Manrope",
                                                width: "fit-content", height: "22px",
                                                color: "#11141A", fontWeight: 700,
                                            }}>
                                                Jack Tohas
                                            </Typography>
                                            <Typography style={{
                                                fontFamily: "Manrope", height: "21px",
                                                fontSize: "14px", color: "#11141A",
                                                width: "fit-content", fontWeight: 400,
                                            }}>
                                                Tech Help
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {this.state.userType != "Hire A Teen" && (
                                        <Box style={{
                                            alignItems: "start", display: "flex", justifyContent: "center",
                                            flexDirection: "column", gap: "6px",
                                        }}>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                <Typography style={{
                                                    color: "#161C2D", fontWeight: 400, fontSize: "14px",
                                                    width: "fit-content", fontFamily: "Manrope",
                                                }}>
                                                    3891 Ranchview Dr. Richardson, California 62639
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <img style={{ height: "15px", width: "15px" }}
                                                    src={timer.default}
                                                />
                                                <Typography style={{
                                                    color: "#161C2D", fontWeight: 400, fontSize: "15px",
                                                    width: "fit-content", height: "21px",
                                                }}>
                                                    Once
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box style={{
                                    display: "flex", alignItems: "center",
                                    gap: "35px", paddingRight: "20px",
                                }}>
                                    <Box style={{
                                        display: "flex", flexDirection: "column",
                                        gap: "2px", alignItems: "center"
                                    }}>
                                        <IconButton style={{
                                            height: "60px", width: "60px", borderRadius: "10px",
                                            padding: "12px 24px", background: "#F7F8FA"
                                        }}>
                                            <EmailOutlinedIcon style={{ color: "#11141A" }} />
                                        </IconButton>
                                        <Typography style={{
                                            width: "fit-content", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                            fontSize: "12px", fontWeight: 400
                                        }}>
                                            Message
                                        </Typography>
                                    </Box>
                                    {this.rescheduleIcon2()}
                                    {this.acceptIcon1()}
                                    {this.cancelIcon2()}
                                    {this.declineIcon2()}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {this.state.openAllBookingsOrPendingRequest == "Pending Requests" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                width: "fit-content", height: "33px",
                                fontSize: "24px", fontWeight: 600,
                                fontFamily: "Manrope", color: "#101928",
                            }}>
                                Requests Sent
                            </Typography>
                            <Box style={{
                                display: "flex", width: "100%",
                                height: "106px", borderRadius: "8px",
                                justifyContent: "space-between", borderRight: "2px solid #5E70DD",
                            }}>
                                <Box style={{ display: "flex", gap: "12px", padding: "12px" }}>
                                    <img
                                        src={bookingProfilePic.default}
                                        alt="profile_pic"
                                        style={{
                                            height: "71px", width: "78px", borderRadius: "8px"
                                        }}
                                    />
                                    <Box style={{
                                        display: "flex", justifyContent: "center", flexDirection: "column",
                                    }}>
                                        <Typography style={{
                                            width: "fit-content", height: "21px",
                                            fontSize: "10px", fontWeight: 400,
                                            color: "#11141A", fontFamily: "Manrope",
                                        }}>
                                            Sun 12 July 2024 at 9:00pm
                                        </Typography>
                                        <Typography style={{
                                            fontSize: "16px", fontWeight: 700,
                                            color: "#11141A", fontFamily: "Manrope",
                                            width: "fit-content", height: "22px",
                                        }}>
                                            Mike Stark
                                        </Typography>
                                        <Typography style={{
                                            width: "fit-content", height: "21px",
                                            fontSize: "14px", fontFamily: "Manrope",
                                            color: "#11141A", fontWeight: 400,
                                        }}>
                                            Intergenerational Activities
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={{
                                    display: "flex", alignItems: "center",
                                    gap: "35px", paddingRight: "20px",
                                }}>
                                    <Box style={{
                                        gap: "2px", alignItems: "center",
                                        display: "flex", flexDirection: "column",
                                    }}>
                                        <IconButton style={{
                                            padding: "12px 24px", background: "#F7F8FA",
                                            height: "60px", width: "60px",
                                            borderRadius: "10px",
                                        }}>
                                            <EmailOutlinedIcon style={{ color: "#11141A" }} />
                                        </IconButton>
                                        <Typography style={{
                                            width: "fit-content", height: "21px",
                                            fontSize: "12px", fontWeight: 400,
                                            color: "#11141A", fontFamily: "Manrope",
                                        }}>
                                            Message
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <IconButton style={{
                                            height: "60px", width: "60px", borderRadius: "10px",
                                            padding: "12px 24px", background: "#F7F8FA"
                                        }}
                                            data-test-id="reschedulePopupSecondTestBtn3"
                                            onClick={() => this.handleReschedulePopup()}
                                        >
                                            <img
                                                src={rescheduleLogo.default}
                                                alt="reschedule"
                                                style={{ height: "21px", width: "21px" }}
                                            />
                                        </IconButton>
                                        <Typography style={{
                                            width: "fit-content", height: "21px",
                                            fontSize: "12px", fontWeight: 400,
                                            color: "#11141A", fontFamily: "Manrope",
                                        }}>
                                            Reschedule
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <IconButton style={{
                                            borderRadius: "10px",
                                            height: "60px", width: "60px",
                                            padding: "12px 24px", background: "#F7F8FA"
                                        }}
                                            data-test-id="cancelPopupSecondTestBtn3"
                                            onClick={(event) => this.handleCancelPopup(event)}
                                        >
                                            <CancelIcon style={{ color: "#11141A" }} />
                                        </IconButton>
                                        <Typography style={{
                                            fontSize: "12px", fontWeight: 400,
                                            width: "fit-content", height: "21px",
                                            color: "#11141A", fontFamily: "Manrope",
                                        }}>
                                            Cancel
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {this.state.openAllBookingsOrPendingRequest == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                fontFamily: "Manrope", fontWeight: 600,
                                fontSize: "24px", color: "#101928",
                                width: "fit-content", height: "33px",
                            }}>
                                Past
                            </Typography>
                            <Box style={{
                                display: "flex", borderRadius: "8px",
                                borderRight: "2px solid #5E70DD", justifyContent: "space-between",
                                height: "106px", width: "100%",
                            }}>
                                <Box style={{ display: "flex", gap: "20px" }}>
                                    <Box style={{ display: "flex", padding: "12px", gap: "12px" }}>
                                        <img
                                            alt="profile_pic"
                                            style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                            src={bookingProfilePic.default}
                                        />
                                        <Box style={{
                                            flexDirection: "column", display: "flex",
                                            justifyContent: "center",
                                        }}>
                                            <Typography style={{
                                                fontFamily: "Manrope", color: "#11141A",
                                                fontSize: "10px", fontWeight: 400, height: "21px",
                                                width: "fit-content",
                                            }}>
                                                Sun 18 June 2023 at 8:00pm
                                            </Typography>
                                            <Typography style={{
                                                fontSize: "16px", height: "22px", fontFamily: "Manrope",
                                                width: "fit-content",
                                                color: "#11141A", fontWeight: 700,
                                            }}>
                                                Jack Smith
                                            </Typography>
                                            <Typography style={{
                                                fontFamily: "Manrope", color: "#11141A",
                                                width: "fit-content", height: "21px",
                                                fontSize: "14px", fontWeight: 400,
                                            }}>
                                                Tech Help
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {this.state.userType != "Hire A Teen" && (
                                        <Box style={{
                                            display: "flex", justifyContent: "center", alignItems: "start",
                                            flexDirection: "column", gap: "6px",
                                        }}>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                <Typography style={{
                                                    color: "#161C2D", fontWeight: 400,
                                                    width: "fit-content", fontFamily: "Manrope",
                                                    fontSize: "14px",
                                                }}>
                                                    3891 Ranchview Dr. Richardson, California 62639
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <img
                                                    style={{ height: "15px", width: "15px" }}
                                                    src={timer.default}
                                                />
                                                <Typography style={{
                                                    fontSize: "15px", height: "21px", width: "fit-content",
                                                    color: "#161C2D", fontWeight: 400,
                                                }}>
                                                    Once
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {this.state.userType == "Hire A Teen" && (
                                    <Box style={{
                                        display: "flex", alignItems: "center",
                                        gap: "20px", paddingRight: "20px",
                                    }}>
                                        <Box style={{
                                            display: "flex", flexDirection: "column",
                                            gap: "4px", alignItems: "center"
                                        }}>
                                            <IconButton style={{
                                                height: "60px", width: "60px", borderRadius: "10px",
                                                padding: "12px 24px", background: "#F7F8FA"
                                            }}
                                                data-test-id="receiptPopupTestBtn"
                                                onClick={(event) => this.handleReceiptPopup(event)}
                                            >
                                                <DescriptionOutlinedIcon style={{ color: "#0F172A" }} />
                                            </IconButton>
                                            <Typography style={{
                                                width: "fit-content", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                                fontSize: "12px", fontWeight: 400
                                            }}>
                                                See Receipt
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            gap: "4px", alignItems: "center",
                                            display: "flex", flexDirection: "column",
                                        }}>
                                            <IconButton style={{
                                                padding: "12px 24px", background: "#F7F8FA",
                                                height: "60px", width: "60px", borderRadius: "10px",
                                            }}>
                                                <SearchIcon style={{ color: "#0F172A" }} />
                                            </IconButton>
                                            <Typography style={{
                                                fontSize: "12px", fontWeight: 400,
                                                width: "fit-content", height: "21px",
                                                color: "#11141A", fontFamily: "Manrope",
                                            }}>
                                                Rebook this Service
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    {this.state.openAllBookingsOrPendingRequest == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                width: "fit-content", height: "33px", fontFamily: "Manrope",
                                fontSize: "24px", fontWeight: 600, color: "#101928"
                            }}>
                                Canceled
                            </Typography>
                            <Box style={{
                                borderRight: "2px solid #5E70DD", height: "106px",
                                justifyContent: "space-between",
                                width: "100%", borderRadius: "8px", display: "flex",
                            }}>
                                <Box style={{ display: "flex", gap: "20px" }}>
                                    <Box style={{ display: "flex", padding: "12px", gap: "12px" }}>
                                        <img
                                            style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                            alt="profile_pic"
                                            src={bookingProfilePic.default}
                                        />
                                        <Box style={{
                                            justifyContent: "center",
                                            flexDirection: "column", display: "flex",
                                        }}>
                                            <Typography style={{
                                                fontFamily: "Manrope", width: "fit-content",
                                                fontSize: "10px", fontWeight: 400, height: "21px",
                                                color: "#11141A",
                                            }}>
                                                Sun 14 July 2023 at 3:00pm
                                            </Typography>
                                            <Typography style={{
                                                fontSize: "16px", width: "fit-content",
                                                height: "22px", fontFamily: "Manrope",
                                                color: "#11141A", fontWeight: 700,
                                            }}>
                                                Jack Tohas
                                            </Typography>
                                            <Typography style={{
                                                height: "21px", fontWeight: 400,
                                                fontFamily: "Manrope", width: "fit-content",
                                                fontSize: "14px", color: "#11141A"
                                            }}>
                                                Tech Help
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {this.state.userType != "Hire A Teen" && (
                                        <Box style={{
                                            display: "flex", justifyContent: "center",
                                            flexDirection: "column", gap: "6px", alignItems: "start"
                                        }}>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                <Typography style={{
                                                    width: "fit-content", fontFamily: "Manrope",
                                                    color: "#161C2D", fontWeight: 400,
                                                    fontSize: "14px",
                                                }}>
                                                    3891 Ranchview Dr. Richardson, California 62639
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                <img style={{ height: "15px", width: "15px" }}
                                                    src={timer.default}
                                                />
                                                <Typography style={{
                                                    fontSize: "15px", width: "fit-content",
                                                    color: "#161C2D", fontWeight: 400,
                                                    height: "21px",
                                                }}>
                                                    Once
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {this.state.userType == "Hire A Teen" && (
                                    <Box style={{
                                        gap: "25px", paddingRight: "20px",
                                        display: "flex", alignItems: "center",
                                    }}>
                                        <Box style={{
                                            display: "flex", flexDirection: "column",
                                            gap: "4px", alignItems: "center"
                                        }}>
                                            <IconButton style={{
                                                height: "60px", width: "60px", borderRadius: "10px",
                                                padding: "12px 24px", background: "#F7F8FA"
                                            }}>
                                                <SearchIcon style={{ color: "#0F172A" }} />
                                            </IconButton>
                                            <Typography style={{
                                                width: "fit-content", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                                fontSize: "12px", fontWeight: 400
                                            }}>
                                                Rebook this Service
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
                <Popover
                    open={openCancelBtn}
                    anchorEl={this.state.openCancelPopup}
                    onClose={() => this.closeCancelPopup()}
                    anchorReference={"none"}
                    data-test-id="closeTest1"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box style={{
                        width: "600px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "25px",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px"
                        }}
                            data-test-id="closeCancelIconTestBtn"
                            onClick={() => this.closeCancelPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            color: "#0F172A", fontSize: "24px", fontFamily: "Manrope",
                            fontWeight: 700, padding: "0px 30px",
                        }}>
                            Are you sure you want to cancel your Booking?
                        </Typography>
                        <Box style={{ display: "flex", gap: "12px", padding: "0px 30px", }}>
                            <img
                                alt="profile_pic"
                                style={{
                                    height: "71px",
                                    width: "78px", borderRadius: "8px"
                                }}
                                src={bookingProfilePic.default}
                            />
                            <Box style={{
                                flexDirection: "column",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Typography style={{
                                    height: "21px", fontFamily: "Manrope",
                                    fontSize: "10px", fontWeight: 400,
                                    color: "#11141A", width: "fit-content",
                                }}>
                                    Sun 16 July 2024 at 9:00pm
                                </Typography>
                                <Typography style={{
                                    fontSize: "16px", fontWeight: 700,
                                    width: "fit-content", height: "22px",
                                    color: "#11141A", fontFamily: "Manrope",
                                }}>
                                    Jack Snow
                                </Typography>
                                <Typography style={{
                                    fontSize: "14px", fontWeight: 400,
                                    width: "fit-content", height: "21px",
                                    fontFamily: "Manrope",
                                    color: "#11141A",
                                }}>
                                    Tech Help
                                </Typography>
                            </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Button variant="contained"
                            style={{
                                background: "#5E70DD", color: "#FFFFFF",
                                fontSize: "16px", fontWeight: 700,
                                width: "fit-content", height: "43px",
                                fontFamily: "Manrope", borderRadius: "10px",
                                textTransform: "none", alignSelf: "center"
                            }}
                        > Cancel Booking
                        </Button>
                    </Box>
                </Popover>
                <Popover
                    open={openReceiptBtn}
                    anchorEl={this.state.openReceiptPopup}
                    onClose={() => this.closeReceiptPopup()}
                    anchorReference={"none"}
                    data-test-id="closeTest2"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box style={{
                        width: "908px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "5px",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px"
                        }}
                            data-test-id="closeReceiptIconTestBtn"
                            onClick={() => this.closeReceiptPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            color: "#0F172A", fontSize: "24px",
                            fontWeight: 700, padding: "0px 30px",
                            fontFamily: "Manrope",
                        }}>
                            Booking Receipt
                        </Typography>
                        <Box style={{ display: "flex", gap: "40px" }}>
                            <Box style={{
                                display: "flex", gap: "12px",
                                padding: "10px 30px", height: "fit-content"
                            }}>
                                <img
                                    alt="profile_pic"
                                    style={{
                                        height: "71px",
                                        width: "78px", borderRadius: "8px"
                                    }}
                                    src={bookingProfilePic.default}
                                />
                                <Box style={{
                                    flexDirection: "column",
                                    display: "flex", justifyContent: "center"
                                }}>
                                    <Typography style={{
                                        fontSize: "16px", fontWeight: 700,
                                        color: "#11141A", fontFamily: "Manrope",
                                        width: "fit-content", height: "22px",
                                    }}>
                                        Jack Sparrow
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        color: "#11141A",
                                        width: "fit-content", height: "21px",
                                        fontFamily: "Manrope",
                                    }}>
                                        Intergenerational Activities
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{
                                flexDirection: "column", width: "310px",
                                justifyContent: "center", padding: "0px 30px",
                                display: "flex", gap: "10px"
                            }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: "center", gap: "16px"
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#707070",
                                        fontSize: "14px", fontWeight: 400,
                                    }}>
                                        Booking ID
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#121212",
                                        fontSize: "14px", fontWeight: 500,
                                    }}>
                                        0000123456789
                                    </Typography>
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    alignItems: "center", gap: "16px",
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        fontFamily: "Manrope", color: "#707070",
                                    }}>
                                        Date
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 500,
                                        fontFamily: "Manrope", color: "#121212",
                                    }}>
                                        Mar 22, 2023
                                    </Typography>
                                </Box>
                                <Box style={{
                                    alignItems: "center", gap: "16px",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope",
                                        fontSize: "14px", color: "#707070",
                                        fontWeight: 400,
                                    }}>
                                        Time
                                    </Typography>
                                    <Typography style={{
                                        color: "#121212",
                                        fontFamily: "Manrope",
                                        fontSize: "14px", fontWeight: 500,
                                    }}>
                                        07:80 AM
                                    </Typography>
                                </Box>
                                <Box style={{
                                    justifyContent: "space-between",
                                    display: 'flex',
                                    alignItems: "center", gap: "16px"
                                }}>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        fontFamily: "Manrope",
                                        color: "#707070",
                                    }}>
                                        Payment Method
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px",
                                        fontFamily: "Manrope", color: "#121212",
                                        fontWeight: 500,
                                    }}>
                                        Credit Card
                                    </Typography>
                                </Box>
                                <Box style={{
                                    alignItems: "center", gap: "16px",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#707070",
                                        fontSize: "14px", fontWeight: 600,
                                    }}>
                                        Amount
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#121212",
                                        fontSize: "16px", fontWeight: 500,
                                    }}>
                                        $50,00
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider
                            style={{ marginTop: "15px" }}
                            orientation="horizontal" flexItem
                        />
                    </Box>
                </Popover>
                <Modal sx={{
                    overflow: "auto", display: 'flex',
                    alignItems: 'center', paddingTop: "260px",
                    justifyContent: 'center', paddingBottom: "30px"
                }}
                    open={this.state.openReschedulePopup}
                >
                    <Card style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        width: "900px", height: "650px", background: "#F1F5F9",
                        display: "flex", flexDirection: "column",
                        padding: "0px 60px 10px 80px",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end", left: "50px"
                        }}
                            onClick={() => this.closeReschedulePopup()}
                            data-test-id="closeRescheduleIconTestBtn"
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Box style={{ display: "flex", gap: "40px", alignItems: "center" }}>
                            <img
                                src={bookingProfilePic.default}
                                alt="profile_pic"
                                style={{
                                    borderRadius: "4px",
                                    height: "176px", width: "148px",
                                }}
                            />
                            <Box style={{
                                display: 'flex', flexDirection: "column",
                                gap: "6px"
                            }}>
                                <Typography style={{
                                    height: "36px", width: "fit-content",
                                    fontFamily: "Manrope", color: "#141414",
                                    fontSize: "24px", fontWeight: 600,
                                }}>
                                    Jack Tohas
                                </Typography>
                                <Box style={{ display: "flex", gap: "25px", width: "fit-content" }}>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <img src={location.default} />
                                        <Typography style={{
                                            fontFamily: "Manrope", fontWeight: 600, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}>  New York
                                            <span style={{
                                                marginLeft: "20px",
                                                color: "rgba(20, 20, 20, 0.7)"
                                            }}>.
                                            </span>
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <Typography style={{
                                            fontFamily: "Manrope", fontWeight: 600, width: "fit-content",
                                            fontSize: "16px", color: "#141414"
                                        }}> State:
                                        </Typography>
                                        <Typography style={{
                                            fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}> New York
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <Typography style={{
                                            fontFamily: "Manrope", fontWeight: 600, width: "fit-content",
                                            fontSize: "16px", color: "#141414"
                                        }}> ZIP Code:
                                        </Typography>
                                        <Typography style={{
                                            fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}> 10956
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography style={{
                                    fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                    fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                }}> {`$ ${120}/h`}
                                </Typography>
                                <Typography style={{
                                    height: "24px", width: "fit-content",
                                    fontFamily: "Manrope", color: "#141414",
                                    fontSize: "16px", fontWeight: 700,
                                }}>
                                    Services
                                </Typography>
                                <Select
                                    data-test-id="calPopupDropdownTestBtn"
                                    defaultValue={"Intergenerational Activities"}
                                    style={{
                                        width: "208px", height: "30px", borderRadius: "10px",
                                        fontSize: "12px", color: "#393939", outline: "none",
                                        border: "none", fontFamily: "Manrope", fontWeight: 700,
                                        cursor: "pointer", background: "#FFFFFF",
                                        justifyContent: "center", padding: "5px 8.5px",
                                    }}
                                    variant="standard"
                                    disableUnderline
                                >
                                    <MenuItem value={"Intergenerational Activities"} >
                                        {"Intergenerational Activities"}
                                    </MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", marginTop: "20px", justifyContent: "space-between" }}>
                            <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterDayjs}>
                                <DatePicker open={this.state.openCalender}
                                    data-test-id="calenderPopupTestBtn"
                                    onChange={(event) => this.handleDatePicker(event)}
                                    minDate={dayjs()}
                                    showDaysOutsideCurrentMonth
                                    slots={{ toolbar: this.CustomToolbarCal }}
                                    slotProps={{
                                        day: {
                                            sx: {
                                                '&.MuiPickersDay-root.Mui-selected': {
                                                    backgroundColor: '#F0E5FF',
                                                    border: "1px solid #E1CCFF",
                                                    color: "#6200EA", fontWeight: 700
                                                },
                                                "&.MuiPickersDay-today": {
                                                    border: "none", color: "#0F172A",
                                                    fontWeight: 700
                                                },
                                            }
                                        },
                                        popper: {
                                            sx: {
                                                ".MuiPaper-root": {
                                                    top: "-60px",
                                                    position: "absolute",
                                                    border: "1px solid #CBD5E1",
                                                    boxShadow: "none",
                                                    borderRadius: "8px",
                                                    width: "374px"
                                                },
                                            },
                                        },
                                        layout: {
                                            sx: {
                                                "&.MuiPickersLayout-root": {
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }
                                            }
                                        },
                                        nextIconButton: {
                                            sx: {
                                                left: "190px"
                                            }
                                        },
                                        calendarHeader: {
                                            sx: {
                                                ".MuiPickersCalendarHeader-label": {
                                                    color: '#0F172A', fontSize: '14px',
                                                    fontFamily: 'Inter', fontWeight: 700
                                                },
                                                flexDirection: "row-reverse",
                                            }
                                        },
                                        switchViewButton: {
                                            sx: {
                                                display: 'none'
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <Box style={{
                                display: "flex", alignItems: "center",
                                flexDirection: "column", gap: "30px"
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <Box style={{
                                        width: "360px", border: "1px solid #CBD5E1",
                                        borderRadius: '10px', height: "126px", background: "#FFFFFF",
                                        display: "flex", gap: "15px", flexDirection: "column"
                                    }}>
                                        <Typography style={{
                                            width: "fit-content", margin: "10px 0px 0px 20px",
                                            fontFamily: "Manrope", fontSize: '20px',
                                            fontWeight: 600, color: "#272727",
                                        }}>
                                            Start Time
                                        </Typography>
                                        <TimePicker
                                            defaultValue={dayjs('2022-04-17T15:30')}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        ".MuiOutlinedInput-root": {
                                                            display: "flex",
                                                            flexDirection: "row-reverse",
                                                            width: "240px", paddingLeft: "0px",
                                                            margin: "0px 0px 0px 20px", gap: "10px",
                                                        },
                                                        ".MuiSvgIcon-root": {
                                                            color: "#0F172A"
                                                        }
                                                    },
                                                    InputProps: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <ArrowDropDownIcon style={{ color: "#0F172A" }} />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <Box style={{
                                        width: "360px", border: "1px solid #CBD5E1",
                                        borderRadius: '10px', height: "126px", background: "#FFFFFF",
                                        display: "flex", gap: "15px", flexDirection: "column"
                                    }}>
                                        <Typography style={{
                                            width: "fit-content", margin: "10px 0px 0px 20px",
                                            fontFamily: "Manrope", fontSize: '20px',
                                            fontWeight: 600, color: "#272727"
                                        }}>
                                            End Time
                                        </Typography>
                                        <TimePicker
                                            defaultValue={dayjs('2022-04-17T15:30')}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        ".MuiOutlinedInput-root": {
                                                            display: "flex",
                                                            flexDirection: "row-reverse",
                                                            width: "240px", paddingLeft: "0px",
                                                            margin: "0px 0px 0px 20px", gap: "10px",
                                                        },
                                                        ".MuiSvgIcon-root": {
                                                            color: "#0F172A"
                                                        }
                                                    },
                                                    InputProps: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <ArrowDropDownIcon style={{ color: "#0F172A" }} />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                                <Button style={{
                                    background: "#5E70DD", color: "#FFFFFF",
                                    textTransform: "none", height: "43px", width: "344px",
                                    borderRadius: "10px", padding: "12px 24px",
                                    fontFamily: "Roboto", fontSize: '16px',
                                    fontWeight: 700,
                                }}>
                                    Reschedule This Teen
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </Modal>
                <Modal
                    sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                    open={this.state.openAcceptPopup}
                >
                    <Card style={{
                        display: "flex", flexDirection: "column", gap: "25px",
                        width: "600px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                    }}>
                        <IconButton style={{
                            padding: "20px 20px 0px 0px",
                            alignSelf: "flex-end",
                        }}
                            data-test-id="closeAcceptIconTestBtn"
                            onClick={() => this.closeAcceptPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            fontWeight: 700, padding: "0px 30px", fontFamily: "Manrope",
                            color: "#0F172A", fontSize: "24px",
                        }}>
                            Are you sure you want to Accept your Booking?
                        </Typography>
                        <Box style={{ display: "flex", gap: "12px", padding: "0px 30px", }}>
                            <img
                                alt="profile_pic"
                                style={{
                                    height: "71px",
                                    width: "78px", borderRadius: "8px"
                                }}
                                src={bookingProfilePic.default}
                            />
                            <Box style={{
                                flexDirection: "column", display: "flex",
                                justifyContent: "center"
                            }}>
                                <Typography style={{
                                    height: "21px", fontFamily: "Manrope",
                                    color: "#11141A", width: "fit-content",
                                    fontSize: "10px", fontWeight: 400,
                                }}>
                                    Sun 14 July 2020 at 9:00pm
                                </Typography>
                                <Typography style={{
                                    width: "fit-content", height: "22px",
                                    fontSize: "16px", fontWeight: 700,
                                    color: "#11141A", fontFamily: "Manrope",
                                }}>
                                    Jack Peek
                                </Typography>
                                <Typography style={{
                                    color: "#11141A",
                                    width: "fit-content", height: "21px",
                                    fontFamily: "Manrope",
                                    fontSize: "14px", fontWeight: 400,
                                }}>
                                    Tech Help
                                </Typography>
                            </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Box style={{
                            display: "flex", alignSelf: "flex-end",
                            gap: "20px", marginRight: "20px"
                        }}>
                            <Button variant="contained"
                                style={{
                                    background: "#FFFFFF", color: "#5E70DD",
                                    textTransform: "none", border: "1px solid #5E70DD",
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    width: "143px", height: "43px",
                                    fontSize: "16px", fontWeight: 700,
                                }}
                            > Cancel
                            </Button>
                            <Button variant="contained"
                                style={{
                                    background: "#5E70DD", color: "#FFFFFF",
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    fontSize: "16px", fontWeight: 700,
                                    textTransform: "none",
                                    width: "143px", height: "43px",
                                }}
                            > Accept
                            </Button>
                        </Box>
                    </Card>
                </Modal>
                <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.openDeclinePopup}
                >
                    <Card style={{
                        width: "600px", height: "350px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "25px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#FFFFFF",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px",
                        }}
                            data-test-id="closeDeclineIconTestBtn"
                            onClick={() => this.closeDeclinePopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            fontWeight: 700, fontFamily: "Manrope", color: "#0F172A",
                            fontSize: "24px", padding: "0px 30px",
                        }}>
                            Why are you declining?
                        </Typography>
                        <Box style={{ padding: "0px 30px" }}>
                            <TextField
                                variant="standard"
                                data-test-id="declineTextTestBtn"
                                type="text"
                                value={this.state.declineText}
                                style={{
                                    width: "90%", height: "80px", borderRadius: "8px",
                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                    padding: "12px 16px", outline: "none",
                                    fontSize: "16px", textAlign: "center",
                                }}
                                sx={{
                                    input: {
                                        color: '#A8ADB7', fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope"
                                    },
                                }}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event) => this.changeDeclineText(event)}
                                placeholder="Enter text"
                            >
                            </TextField>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Box style={{
                            display: "flex", alignSelf: "flex-end",
                            gap: "20px", marginRight: "20px"
                        }}>
                            <Button variant="contained"
                                style={{
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    fontSize: "16px", fontWeight: 700, background: "#FFFFFF",
                                    color: "#5E70DD", border: "1px solid #5E70DD",
                                    textTransform: "none",
                                    width: "143px", height: "43px",
                                }}
                            > Cancel
                            </Button>
                            <Button variant="contained"
                                style={{
                                    color: "#FFFFFF", width: "143px", height: "43px",
                                    fontSize: "16px", fontWeight: 700,
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    textTransform: "none", background: "#5E70DD"
                                }}
                            > Decline
                            </Button>
                        </Box>
                    </Card>
                </Modal>
            </>
        )
    }

    CustomToolbarCal = () => (
        <Box style={{ display: "flex", alignItems: "center", padding: "10px 0px 0px 60px" }}>
            <Typography style={{
                width: "fit-content", fontFamily: "Manrope",
                fontWeight: 700, fontSize: "20px", color: "#272727"
            }}>
                Confirm Start Date
            </Typography>
        </Box>
    );

    rescheduleIcon1() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{
                    display: "flex",
                    gap: "2px", flexDirection: "column",
                }}>
                    <IconButton style={{
                        background: "#F7F8FA", borderRadius: "10px",
                        height: "60px", width: "60px", padding: "12px 24px",
                    }}
                        data-test-id="reschedulePopupTestBtn"
                        onClick={() => this.handleReschedulePopup()}
                    >
                        <img
                            alt="reschedule"
                            src={rescheduleLogo.default}
                            style={{ height: "21px", width: "21px" }}
                        />
                    </IconButton>
                    <Typography style={{
                        width: "fit-content", height: "21px",
                        color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400
                    }}>
                        Reschedule
                    </Typography>
                </Box>
            )
        )
    }

    rescheduleIcon2() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <IconButton style={{
                        height: "60px", width: "60px", borderRadius: "10px",
                        padding: "12px 24px", background: "#F7F8FA"
                    }}
                        data-test-id="reschedulePopupSecondTestBtn"
                        onClick={() => this.handleReschedulePopup()}
                    >
                        <img
                            src={rescheduleLogo.default}
                            alt="reschedule"
                            style={{ height: "21px", width: "21px" }}
                        />
                    </IconButton>
                    <Typography style={{
                        width: "fit-content", height: "21px", color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400
                    }}>
                        Reschedule
                    </Typography>
                </Box>
            )
        )
    }

    acceptIcon1() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <IconButton style={{
                        padding: "12px 24px", background: "#F7F8FA",
                        height: "60px", width: "60px", borderRadius: "10px",
                    }}
                        data-test-id="acceptPopupTestBtn"
                        onClick={() => this.handleAcceptPopup()}
                    >
                        <CheckCircleIcon style={{ color: "#000000" }} />
                    </IconButton>
                    <Typography style={{
                        width: "fit-content", height: "21px",
                        fontSize: "12px", fontWeight: 400,
                        color: "#11141A", fontFamily: "Manrope",
                    }}>
                        Accept
                    </Typography>
                </Box>
            )
        )
    }

    cancelIcon1() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <IconButton style={{
                        height: "60px", width: "60px",
                        borderRadius: "10px",
                        padding: "12px 24px", background: "#F7F8FA",
                    }}
                        data-test-id="cancelPopupTestBtn"
                        onClick={(event) => this.handleCancelPopup(event)}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </IconButton>
                    <Typography style={{
                        fontSize: "12px", fontWeight: 400,
                        width: "fit-content", height: "21px",
                        color: "#11141A", fontFamily: "Manrope",
                    }}>
                        Cancel
                    </Typography>
                </Box>
            )
        )
    }

    cancelIcon2() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{
                    flexDirection: "column", gap: "2px",
                    display: "flex",
                }}>
                    <IconButton style={{
                        height: "60px", width: "60px", background: "#F7F8FA",
                        borderRadius: "10px", padding: "12px 24px",
                    }}
                        data-test-id="cancelPopupSecondTestBtn"
                        onClick={(event) => this.handleCancelPopup(event)}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </IconButton>
                    <Typography style={{
                        color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400,
                        width: "fit-content", height: "21px",
                    }}>
                        Cancel
                    </Typography>
                </Box>
            )
        )
    }

    declineIcon1() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <IconButton style={{
                        padding: "12px 24px", width: "60px",
                        height: "60px", background: "#F7F8FA",
                        borderRadius: "10px",
                    }}
                        data-test-id="declinePopupTestBtn1"
                        onClick={() => this.handleDeclinePopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </IconButton>
                    <Typography style={{
                        width: "fit-content", height: "21px",
                        fontSize: "12px", fontWeight: 400,
                        color: "#11141A", fontFamily: "Manrope",
                    }}>
                        Decline
                    </Typography>
                </Box>
            )
        )
    }

    declineIcon2() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <IconButton style={{
                        padding: "12px 24px", width: "60px", borderRadius: "10px",
                        height: "60px", background: "#F7F8FA",
                    }}
                        data-test-id="declinePopupTestBtn2"
                        onClick={() => this.handleDeclinePopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </IconButton>
                    <Typography style={{
                        fontSize: "12px", fontWeight: 400,
                        width: "fit-content", height: "21px",
                        color: "#11141A", fontFamily: "Manrope",
                    }}>
                        Decline
                    </Typography>
                </Box>
            )
        )
    }

    customDateHeader = ({ label, date }: any) => {
        const formattedDate = moment(date).format('D'); // Format date as day of month
        const label1 = label.split(" ")
        return (
            <div style={webStyles.headerContainerStyle}>
                <span style={{ ...webStyles.dayNameStyle, fontWeight: 700 }}>{label1[1]}</span>
                <span style={{
                    ...webStyles.dateStyle,
                    backgroundColor: `${formattedDate == moment(new Date()).format('D') ? "#4F55FB" : "#FFFFFF"}`,
                    color: `${formattedDate == moment(new Date()).format('D') ? "#E8E8E8" : "#003861"}`, borderRadius: "50%", width: "-moz-available"
                }}>{formattedDate}</span>
            </div>
        );
    };

    renderAvailability = () => {
        const localizer = momentLocalizer(moment);
        const events = [
            {
                title: "Sample Event",
                start: new Date(),
                end: new Date(moment().add(1, "hours").toDate()),
                allDay: false,
                bgColor: '#5E70DD'
            },
        ]
        const date = moment();
        const formattedDate = date.format('MM/DD/YYYY');
        return <Box>
            <Typography style={{ fontFamily: "Manrope", fontSize: "16px", fontWeight: 600, lineHeight: "25.6px", marginBottom: "20px" }}>Choose Availability</Typography>
            <Box style={{ display: "flex", gap: "10%", height: "865px" }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    onSelectEvent={this.onChangeEvents}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView="week"
                    views={['week']}
                    components={{
                        toolbar: this.customToolbar,
                        week: {
                            header: this.customDateHeader,
                        },
                    }}
                    eventPropGetter={this.eventStyleGetter}

                    min={new Date(2024, 0, 1, 8, 0, 0)}
                    defaultDate={new Date()}

                    style={{ height: 500, width: "50%" }}
                />
                <Box>
                    {this.renderCalendar()}
                    <hr style={{ width: "100%", marginBottom: "20px", border: "1px solid #DAEF84" }} />
                    <Box>
                        <Typography style={{
                            fontFamily: "Manrope", color: "#2B2A2A",
                            fontSize: "14px", fontWeight: 700,
                            lineHeight: "20px"
                        }}>Today<span style={{
                            fontFamily: "Manrope", color: "#2B2A2A",
                            fontSize: "14px", fontWeight: 400, lineHeight: "20px"
                        }}>{` ${formattedDate}`}</span></Typography>
                        <hr style={{ width: "100%", marginTop: "20px", marginBottom: "20px", border: "1px solid #DAEF84" }} />

                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
                                <Box style={{ width: "8px", height: "8px", borderRadius: "50px", backgroundColor: "#A7B1ED" }}></Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 600, lineHeight: "16.39px", color: "#2B2A2A" }}>08:15 AM - 09:15 AM</Typography>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 400, lineHeight: "16.39px", color: "#2B2A2A" }}>Tech Help</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
                                <Box style={{ width: "8px", height: "8px", borderRadius: "50px", backgroundColor: "#A7B1ED" }}></Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 600, lineHeight: "16.39px", color: "#2B2A2A" }}>09:00 AM - 10:00 AM</Typography>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 400, lineHeight: "16.39px", color: "#2B2A2A" }}>Indoor Help</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
                                <Box style={{ width: "8px", height: "8px", borderRadius: "50px", backgroundColor: "#A7B1ED" }}></Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 600, lineHeight: "16.39px", color: "#2B2A2A" }}>11:00 AM - 11:45 AM</Typography>
                                    <Typography style={{ fontFamily: "Manrope", fontSize: "12px", fontWeight: 400, lineHeight: "16.39px", color: "#2B2A2A" }}>Tech Help</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    }
    renderCalendar = () => {

        return (
            <div style={{ height: "305px", maxWidth: "335px", width: "100%" }} >
                <this.CustomHeader date={this.state.activeStartDate || new Date()} />
                <ReactCalendar
                    onChange={this.onChangeEvents}
                    value={this.state.activeStartDate}
                    tileClassName={this.tileClassName}
                    showNavigation={false}
                    calendarType="gregory"
                />
                <style>
                    {`
                .custom-tile {
                    background-color: #5E70DD; 
                    border-radius: 50%;
                    width: 47.86px;
                    height: 35px;
                    color: #FFFFFF; 
                  }
                  .react-calendar__month-view__weekdays__weekday {
                    text-decoration: none; 
                  }
                  .end-of-month-tile {
                    color: #9696FF;
                  }
                  .weekend-tile {
                    color: black
                  }
                .react-calendar {
                    border: none;
                    box-shadow: none;
                }
                .no-border-tile {
                    border: none !important;
                }
                `}
                </style>
            </div>
        );
    }

    CustomHeader = ({ date }: any) => {
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
        const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);

        return (
            <div style={{ display: "flex", marginLeft: "8px", marginBottom: "10px", justifyContent: "space-between" }}>
                <span style={{
                    fontFamily: "Manrope", fontSize: "24px", fontWeight: 600,
                    lineHeight: "30px", color: "#474747"
                }}>{`${month} ${year}`}</span>
                <div>
                    <img src={prev.default} style={{ height: "24px", width: "24px" }} onClick={this.prevMonth} />
                    <img src={forward.default} style={{ height: "24px", width: "24px" }} onClick={this.nextMonth} />
                </div>
            </div>
        );
    };

    render() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Box style={{
                    height: "74px", display: "flex", alignItems: "center",
                    borderBottom: "1px solid #C1C7CD", gap: "16px", padding: "0px 60px 0px 40px"
                }}>
                    <Box style={{ display: "flex", alignItems: "center", flexGrow: 1, gap: "40px" }}>
                        <img
                            src={logo.default}
                            alt="senior_year"
                            style={{ width: "195px", height: "22px" }}
                        />
                        <Typography style={{
                            width: "fit-content", height: "26px", color: "#020202", fontFamily: "Manrope",
                            fontSize: "16px", fontWeight: 500
                        }}>
                            Find Jobs
                        </Typography>
                    </Box>
                    <Box style={{
                        display: "flex", alignItems: "center", gap: "16px"
                    }}>
                        <Divider orientation="vertical" flexItem />
                        <Typography style={{
                            width: "fit-content", height: "26px", color: "#020202", fontFamily: "Manrope",
                            fontSize: "16px", fontWeight: 500, cursor: "pointer"
                        }}>
                            David Jones
                        </Typography>
                        <KeyboardArrowDownIcon
                            data-test-id="keyboard-arrow-down-icon"
                            style={{ color: "#000000", cursor: "pointer" }}
                        />
                    </Box>
                </Box>
                <Box style={{ display: "flex", gap: "30px", padding: "60px", flexDirection: "column" }}>
                    <Typography style={{
                        width: "fit-content", height: "29px", color: "#25324B",
                        fontSize: "24px", fontWeight: 700, fontFamily: "Manrope",
                    }}>
                        My Profile
                    </Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            data-test-id="tabsTestBtn"
                            value={this.state.currentTabIndex}
                            onChange={this.handleTabChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: '#5E70DD',
                                },
                            }}
                            sx={{
                                ".Mui-selected": {
                                    color: "#25324B !important"
                                }
                            }}
                        >
                            {!this.hideTabs() && <Tab value="4" sx={webStyles.tabs} label="Overview" />}
                            <Tab value="0" sx={webStyles.tabs} label="Bookings" />
                            {this.hideTabs() && <Tab value="1" sx={webStyles.tabs} label="Availability" />}
                            <Tab value="2" sx={webStyles.tabs} label="Messages" />
                            {!this.hideTabs() && <Tab value="3" sx={webStyles.tabs} label="Payments" />}
                            {this.hideTabs() && <Tab value="3" sx={webStyles.tabs} label="Payouts" />}
                            {this.hideTabs() && <Tab value="4" sx={webStyles.tabs} label="Profile" />}
                            {this.hideTabs() && <Tab value="5" sx={webStyles.tabs} label="Work Preferences and Interests" />}
                            <Tab value="6" sx={webStyles.tabs} label="Settings" />
                        </Tabs>
                    </Box>
                    {(this.state.showEditButton && this.state.currentTabIndex == "4") &&
                        <Typography style={{
                            width: "fit-content", height: "19px", color: "#548CFF",
                            fontSize: "16px", fontWeight: 600, fontFamily: "Roboto",
                            alignSelf: "flex-end", cursor: "pointer"
                        }}
                            data-test-id="editTestBtn"
                            onClick={() => this.handleEdit()}
                        > Edit
                        </Typography>
                    }
                    {this.state.currentTabIndex == "4" &&
                        <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                            <Box style={{ display: "flex", gap: "50px", }}>
                                <Box style={{
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    height: "148px", width: "148px", cursor: "pointer",
                                    border: `${this.userProfileBorder()}`, borderRadius: "100%",
                                    background: "#5E70DD", flexDirection: "column",
                                }}
                                    data-test-id="pictureUploadBoxTest"
                                    onClick={this.uploadPicture}
                                >
                                    <input
                                        data-test-id="inputFileTest"
                                        ref={this.refElmFile}
                                        type="file"
                                        disabled={this.state.showEditButton}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={(event) => this.selectPicture(event)}
                                    />
                                    {!this.state.profileImageUrl &&
                                        <>
                                            <CameraAltOutlinedIcon style={{ color: "#FFFFFF" }} />
                                            <Typography style={{
                                                width: "fit-content", height: "23px", textAlign: "center", color: "#FFFFFF",
                                                fontFamily: "Inter", fontWeight: 700, fontSize: "14px"
                                            }}
                                            > Edit picture
                                            </Typography>
                                        </>
                                    }
                                    {(this.state.profileImageUrl?.length > 0) &&
                                        <img
                                            src={this.state.profileImageUrl}
                                            alt="profile_image"
                                            onClick={this.uploadPicture}
                                            style={{ height: "148px", width: "148px", borderRadius: "100%" }}
                                        />
                                    }
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    <Box style={{ display: "flex", gap: "40px" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <Typography style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > First Name
                                            </Typography>
                                            <TextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="firstNameTestBtn"
                                                variant="standard"
                                                type="text"
                                                value={this.state.userFirstName}
                                                style={{
                                                    width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center", padding: "12px 16px"
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                }}
                                                onChange={(event) => this.changeFirstName(event)}
                                                placeholder="First Name"
                                                InputProps={{ disableUnderline: true }}
                                            >
                                            </TextField>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <Typography style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Last Name
                                            </Typography>
                                            <TextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="lastNameTestBtn"
                                                variant="standard"
                                                type="text"
                                                value={this.state.userLastName}
                                                style={{
                                                    width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center", padding: "12px 16px"
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                }}
                                                onChange={(event) => this.changeLastName(event)}
                                                placeholder="Last Name"
                                                InputProps={{ disableUnderline: true }}
                                            >
                                            </TextField>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "40px" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <Typography style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Email
                                            </Typography>
                                            <TextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="emailTestBtn"
                                                variant="standard"
                                                type="email"
                                                value={this.state.userEmail}
                                                style={{
                                                    width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center", padding: "12px 16px"
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                }}
                                                onChange={(event) => this.changeEmail(event)}
                                                placeholder="Email"
                                                InputProps={{ disableUnderline: true }}
                                            >
                                            </TextField>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <Typography style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Phone Number
                                            </Typography>
                                            <TextField
                                                data-test-id="numberTestBtn"
                                                disabled={this.state.showEditButton}
                                                variant="standard"
                                                type="text"
                                                value={this.state.userNumber}
                                                style={{
                                                    width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center", padding: "12px 16px"
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                }}
                                                onChange={(event) => this.changeNumber(event)}
                                                placeholder="Phone Number"
                                                InputProps={{ disableUnderline: true }}
                                            >
                                            </TextField>
                                        </Box>
                                    </Box>
                                    <Box style={{
                                        display: `${this.hideBoxesReverse()}`,
                                        gap: "4px", flexDirection: "column",
                                    }}>
                                        <Typography style={{
                                            fontWeight: 600, textAlign: "start", color: "#515B6F",
                                            height: "26px", fontFamily: "Inter",
                                            fontSize: "16px", width: "fit-content",
                                        }}
                                        > {`${this.addressParameter()}`}
                                        </Typography>
                                        <TextField
                                            type="text"
                                            data-test-id="streetTestBtn"
                                            disabled={this.state.showEditButton}
                                            style={{
                                                width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                                border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                fontSize: "16px", outline: "none",
                                                justifyContent: "center", padding: "12px 16px"
                                            }}
                                            value={this.state.userStreetAddress}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event) => this.changeStreet(event)}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#010101",
                                                },
                                                input: {
                                                    fontSize: "16px", color: '#515B6F',
                                                    fontWeight: 600, fontFamily: "Manrope",
                                                },
                                            }}
                                            placeholder={`${this.addressParameter()}`}
                                            variant="standard"
                                        >
                                        </TextField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: `${this.hideBoxes()}`, justifyContent: "space-between", gap: "40px" }}>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > School
                                    </Typography>
                                    <TextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="schoolTestBtn"
                                        variant="standard"
                                        type="text"
                                        value={this.state.userSchool}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center", padding: "12px 16px"
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeSchool(event)}
                                        placeholder="School"
                                        InputProps={{ disableUnderline: true }}
                                    >
                                    </TextField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
                                    }}
                                    > Grade
                                    </Typography>
                                    <Select
                                        open={this.handleCheck2()}
                                        disabled={this.state.showEditButton}
                                        data-test-id="gradeTestBtn"
                                        variant="standard"
                                        value={this.state.userGrade}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                            fontSize: "16px", color: "#515B6F", outline: "none",
                                            justifyContent: "center", padding: "37px 16px", cursor: "pointer"
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onClick={() => this.openGradeDropdown()}
                                        onChange={(event: SelectChangeEvent) => this.changeGrade(event)}
                                        disableUnderline
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                data-testid="keyboardArrowDownIcon-grade"
                                                onClick={() => this.openGradeDropdown()}
                                                style={{ color: "#94A3B8" }}
                                            />
                                        )}>
                                        {
                                            this.state.gradeListData?.map((value, key) => (
                                                <MenuItem key={key} value={value} >
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
                                    }}
                                    > GPA
                                    </Typography>
                                    <TextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="gpaTestBtn"
                                        variant="standard"
                                        type="text"
                                        value={this.state.userGPA}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center", padding: "12px 16px"
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeGPA(event)}
                                        placeholder="GPA"
                                        InputProps={{ disableUnderline: true }}
                                    >
                                    </TextField>
                                </Box>
                            </Box>
                            <Box style={{ display: `${this.hideBoxes()}`, justifyContent: "space-between", gap: "40px" }}>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > {`${this.addressParameter()}`}
                                    </Typography>
                                    <TextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="streetTestBtn"
                                        variant="standard"
                                        type="text"
                                        value={this.state.userStreetAddress}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center", padding: "12px 16px"
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                        onChange={(event) => this.changeStreet(event)}
                                        placeholder={`${this.addressParameter()}`}
                                    >
                                    </TextField>
                                </Box>
                            </Box>
                            <Box style={{
                                display: `${this.hideBoxes()}`,
                                gap: "40px", justifyContent: "space-between",
                            }}>
                                <Box style={{
                                    display: "flex",
                                    gap: "4px", flexDirection: "column"
                                }}>
                                    <Typography style={{
                                        fontFamily: "Inter", fontSize: "16px", color: "#515B6F",
                                        width: "fit-content", height: "26px", fontWeight: 600,
                                        textAlign: "start",
                                    }}
                                    > City
                                    </Typography>
                                    <TextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="cityTestBtn"
                                        variant="standard"
                                        type="text"
                                        value={this.state.userCity}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center", padding: "12px 16px"
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeCity(event)}
                                        placeholder="City"
                                        InputProps={{ disableUnderline: true }}
                                    >
                                    </TextField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > State
                                    </Typography>
                                    <Select
                                        open={this.handleCheck1()}
                                        disabled={this.state.showEditButton}
                                        data-test-id="stateTestBtn"
                                        variant="standard"
                                        value={this.state.userState}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                            fontSize: "16px", color: "#515B6F", outline: "none",
                                            justifyContent: "center", padding: "37px 16px", cursor: "pointer"
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onClick={() => this.openStateDropdown()}
                                        onChange={(event: SelectChangeEvent) => this.changeUserState(event)}
                                        disableUnderline
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                data-testid="keyboardArrowDownIcon-state"
                                                onClick={() => this.openStateDropdown()}
                                                style={{ color: "#94A3B8" }}
                                            />
                                        )}>
                                        {
                                            this.state.stateListData?.map((value, key) => (
                                                <MenuItem key={key} value={value} >
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
                                    }}
                                    > ZIP Code
                                    </Typography>
                                    <TextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="codeTestBtn"
                                        variant="standard"
                                        type="text"
                                        value={this.state.userZipCode}
                                        style={{
                                            width: "335px", height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center", padding: "12px 16px"
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeZipCode(event)}
                                        placeholder="ZIP Code"
                                        InputProps={{ disableUnderline: true }}
                                    >
                                    </TextField>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {(this.state.showSaveButton && this.state.currentTabIndex == "4") &&
                        <Button
                            data-test-id="saveTestBtn"
                            style={{
                                background: "#5E70DD", width: "205px", height: "43px",
                                borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                                fontFamily: "Roboto", fontWeight: 700, fontSize: "16px",
                                textTransform: "none",
                                alignSelf: "flex-end"
                            }}
                            onClick={() => this.handleSave()}
                        > Save
                        </Button>
                    }
                    {
                        (this.state.currentTabIndex == "0") && (this.renderBookings())
                    }
                    {
                        (this.state.currentTabIndex == "5") && (this.renderWorkPreferencesAndInterests())
                    }
                    {this.state.currentTabIndex == "1" && this.renderAvailability()}
                </Box>
            </Box>
        )
    }
}

const webStyles = {

    tabs: {
        color: "#7C8493",
        height: "26px",
        fontFamily: "Manrope",
        fontWeight: "700",
        fontSize: 16,
        textTransform: "none"
    },
    headerContainerStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        height: "62.98px"
    },
    dayNameStyle: {
        fontFamily: "Nunito",
        fontSize: 11.99,
        lineHeight: 14.99,
        color: "#969696"
    },
    dateStyle: {
        backgroundColor: '#f0f0f0',
        color: '#333',
        fontSize: '1.1em',
        padding: '2px 0',
    }

}
// Customizable Area End
