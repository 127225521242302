import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { 
           logo, notify, msgIcon, topImage, petIcon ,  indoorIcon,  outdoorIcon,
           elseIcon,  techIcon,  orgIcon ,  runIcon ,  visitIcon, cropImage, 
           paper1, paper2,  paper3, paper4, paper5, paper6, paper7, paper8, tickIcon,
           timer, mapIcon, calIcon, estimate
          }
            from "./assets"
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    let images = [
      {"img":techIcon, text:"Tech Help"},
      {"img":indoorIcon, text:"Indoor Help"},
      {"img":outdoorIcon, text:"Outdoor Help"},
      {"img":visitIcon, text: "Friendly Visits"},
      {"img":orgIcon, text:"Organizing"},
      {"img":petIcon, text:"Pet Care"},
      {"img":runIcon, text:"Run Errands"},
      {"img":elseIcon, text:"Something Else"},
    ]
    let paperContent = [
      {"img": paper1, "title": "Tech Help", "content": "Your teen assistant can help you troubleshoot and fix minor issues with apps, logins, or wifi for your phone, laptop, or other devices."},
      {"img": paper2, "title": "Indoor Help", "content": "Your teen assistant can help you change lightbulbs, carry in boxes or groceries, water and maintain houseplants, help with meal prep, get laundry and housekeeping done, and more."},
      {"img": paper3, "title": "Outdoor Help", "content": "Your teen assistant can mow and edge your lawn, plant seeds or starters, spread mulch, blow or rake debris, and more."},
      {"img": paper4, "title": "Friendly Visits", "content": "Your teen assistant will stop by at a scheduled time to visit, play games or help with hobbies, and ask if you need help with anything. We'll send a brief report of the visit to you and your trusted circle."},
      {"img": paper5, "title": "Organizing", "content": "Your teen assistant can help you sort through, organize, box up, label, and arrange items in your cabinets, closets, garage, or shed. If you have items to sell online or donate, your teen assistant can help with this too."},
      {"img": paper6, "title": "Pet Care", "content": "Your teen assistant can walk your dog, change the litter box, fill pet food bins, brush or play with your furry family member, clean out the fish tank, and more."},
      {"img": paper7, "title": "Run Errands", "content": "Your teen assistant will use their own transportation to pick up groceries or small household items, pick up mail or packages, drop off items for shipping, take items to charity donation sites, and more."},
      {"img": paper8, "title": "Something Else", "content": "Need something else you don't see here? Our elite teens are capable of handling many tasks. Give us a call at 888-888-8888 to discuss your needs!"},
    ]
    return (

      <Box style={{ overflowX: "hidden" }}>
        <Box style={{ width: "100%", height: "74px", maxWidth: "1440px", border: "1px solid #D6DDEB", paddingLeft: "84px", paddingRight: "84px", display: "flex", alignItems: "center" }}>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Box style={{ display: "flex", maxWidth: "481.87px", width: "100%", justifyContent: "space-around" }}>
              <img src={logo.default} style={{ width: "195px", height: "21px" }} />
              <Box style={{ display: "flex", gap: "16px" }}>
                <Typography style={webStyle.headerText}>Hire a Teen</Typography>
                <Typography style={webStyle.headerText}>Get a Job</Typography>
              </Box>
            </Box>
            <Box style={{ width: "293px", borderLeft: "1px solid #D6DDEB", height: "48px", display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "84px" }}>
              <Box></Box>
              <Typography style={{
                fontFamily: "Manrope", fontSize: "16px", fontWeight: 700, lineHeight: "25.6px",
                textAlign: "center", color: "#39393F"
              }}>David Jones</Typography>
              <Box style={{ display: "flex", gap: "10px" }}>
                <img src={notify.default} />
                <img src={msgIcon.default} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: "100%" }}>
          <Typography style={webStyle.topText}>We’re on a mission disguised as a high school job: Help others. Ensure safety. Build skills. End loneliness.</Typography>
          <Box style={{ position: "relative" }}>
            <img src={topImage.default} style={{ width: "100%" }} />
            <Box style={{ maxWidth: "487px", width: "100%", height: "50px", position: "absolute", top: "35%", left: "33%" }}>
              <Typography style={webStyle.imgText}>SENIOR YEAR IS AN ELITE SQUAD OF TEENS HELPING SENIORS IN YOUR LOCAL COMMUNITY</Typography>
            </Box>
            <Box style={{ position: "absolute", top: "51%", left: "22%" }}>
              <Typography style={{
                fontFamily: "Caudex", fontSize: "48px", fontWeight: 700, lineHeight: "52.8px",color:"#FFFFFF"
              }}>Book trusted teens to help out.</Typography>
              </Box>
                <Box style={{background: "#FFFFFF", boxShadow: "7px 9px 36.1px 0px #0000000F",
                   width: "1023px",  height: "359px",  position: "absolute",
                    top: "70%",   left: "17%",  borderRadius: "8px"}}
                    >
                      <Box style={{display:"flex",marginTop:"40px", justifyContent:"center", gap:"10px"}}>
                      {
                       images.map((item:any)=> 
                        <Box style={{display:"flex"}}>
                          <Box style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems: "center"}}>
                          <img src={item.img.default} style={{height:"40px",width:"40px"}}/>
                          <Typography style={webStyle.iconText}>{item.text}</Typography>

                          </Box>
                        </Box>
                      )
                     }
                     </Box>
                     <Box style={{ display:"flex", flexDirection:"column", marginTop:"30px", marginLeft: "20px"}}>
                     <Typography style={{ fontFamily: "Manrope", fontSize: "15px", fontWeight: 600, 
                     lineHeight: "20.49px", color:"#130055"}}>Choose service</Typography>
                    <Box style={{display:"flex", gap:"12px", marginTop:"30px"}}>
                      {
                        ["iPhone", "Android", "Wifi", "Windows Laptop", "Apple Laptop", "iPad", "Kindle"].map((item)=>
                          <Button style={webStyle.btn}>{item}</Button>
                      )
                      }
                    </Box>
                    <Box style={{display:"flex", gap:"16px", marginTop:"30px"}}>
                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={mapIcon.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Location</MenuItem>
                      </Select>
                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={calIcon.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Date</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={timer.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Estimated Hours</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={estimate.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Frequency</MenuItem>
                      </Select>
                      <Button style={webStyle.searchBtn}>Search</Button>
                    </Box>
                     </Box>

               </Box>
          </Box>
          <Box style={{width:"100%", height:"500px", marginTop:"270px"}}>
                <Typography style={webStyle.lowerText}>Do more with helpful young people.</Typography>
               <Box style={{width:"90%", display:"flex",paddingTop:"10px", justifyContent:"space-around"}}>
                <img src={cropImage.default} />
                <Box style={{display: "flex", maxWidth:"383.41px", width:"100%", flexDirection: "column", gap:"20px",height: "447px",  justifyContent: "center"}}>
                    <Typography style={{ fontFamily: "Manrope", color:"#0B132A", fontSize: "35px", fontWeight: 500, lineHeight: "50px", 
                    }}>Book Teens you can trust</Typography>
                    <Box style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                    <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography>All teens pass a background check</Typography>
                </Box>
                <Box style={{display:"flex", height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography>All teens have local teacher recommendations</Typography>
                </Box>
                <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography>All teens are approved by our team</Typography>
                </Box>
                    </Box>
                
                </Box>
               </Box>
               <Box style={{ width:"100%", paddingLeft: "57px"}}>
               <Box style={{display:"flex",gap:"10px", flexWrap: "wrap", paddingTop:"100px"}}>
                {
                  paperContent.map((item: any)=>
                    <Box style={{display:"flex", flexDirection:"column", padding:"50px", maxWidth:"367px", width:"100%", height:"236px",
                 backgroundColor: "#F1F5F9", borderRadius:"10px"}}>
                    <Box style={{display:"flex", gap:"10px"}}>
                      <img src={item.img.default} style={{ width:"30px", height:"30px" }} />
                      <Typography  style={webStyle.contentTitle} >{item.title}</Typography>
                    </Box>
                    <Typography style={webStyle.contentTypo}>{item.content} </Typography>
                </Box>
                  )
                }
                </Box>

                </Box>
               </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  headerText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "25.6px",
    color:"#020202"
  },
  topText: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
    color: "#1C1E1F",
    textAlign: "center" as "center",
    margin: "5px"
  },
  imgText: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "25.2px",
    color: "#FFFFFF"
  },
  iconText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#AABED3"
  },
  lowerText: {
    fontFamily: "Caudex",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "52.8px",
    color: "#444444",
    textAlign: "center" as "center"
  },
  contentTitle: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32.78px",
    color: "#000000"
  },
  contentTypo: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    width: "301px"
  },
  btn: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    backgroundColor:"#F1F5F9",
    color: "#2E2E2E",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "10px"

  },
  searchBtn: {
    width: "173px",
    height: "50px",
    padding: "16px",
    borderRadius: "12px",
    border: "2px",
    color:"#FFFFFF",
    backgroundColor: "#5E70DD",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.5px",
    textTransform: "capitalize" as "capitalize",
  },
  selectBox: {
    width: "180px",
    height: "49.88px",
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
  }
};
// Customizable Area End
