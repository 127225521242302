import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { bottomImage, image1, image2,gradiant, image3, image4, image5, image6, image7, Logo, landingImg } from "./assets"


const StyledMainDiv = styled(Box)({
    overflowX:"hidden",
    justifyContent: "space-around",
    "@media (max-width: 457px)": {
        backgroundColor: "#F1F5F9",
        justifyContent: "center",
    },
})
const StyledBox = styled(Box)({
    "@media (max-width: 678px)": {
        display:"none"
    },
})

const StyledWrapper = styled(Box)({
        width: "50%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    "@media (max-width: 678px)": {
        width:"100%",
        height:"500px",
        justifyContent:"center"
    },
})
const StyledContent = styled(Box)({
    "@media (max-width: 678px)": {
        flexDirection: "column"
    },
})
const StyledmiddleWrap = styled(Box)({
    "@media (max-width: 678px)": {
        height:"43%"
    },
})
const StyledDiv = styled(Box)({
    "@media (max-width: 678px)": {
        justifyContent:"center"
    },
})
const StyledFooter = styled(Box)({
        width: "89.6%",
         backgroundColor: "#5E70DD",
        height: "264px",
        display: "flex",
        alignItems: "center",
        paddingRight: "80px",
        paddingLeft: "80px",
        justifyContent: "space-between",
      "@media (max-width: 678px)": {
        width: "61.6% !important",
        height: "500px !important",
        justifyContent:"center !important",
        flexDirection:"column"
    },
})
const ContactWrapper = styled(Box)({
  
  "@media (max-width: 678px)": {
    alignItems:"center",
    marginTop: "20px",
    flexDirection:"column"
},
})




// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End 
    // Customizable Area Start
    render() {
        return (
            <StyledMainDiv>
                <Box style={{
                    display: "flex",
                    height: "74px",
                    alignItems: "center",
                    padding: "0px 124px 0px 84px",
                    justifyContent: "space-between",
                }}>
                    <StyledBox>
                    <img src={Logo.default} style={{ width: "195px", height: "21px" }} />
                    </StyledBox>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth:"237px", width:"100%", height:"78px", gap: "10px" }}>
                        <Typography style={{...webStyles.typo,paddingRight:"40px"}} onClick={this.handleNavigation.bind(this,"LoginPage")} >Login</Typography>
                        <Box style={{ color: "#D6DDEB", height: "48px", border: "1px solid #D6DDEB" }}></Box>
                        <Button style={webStyles.btn}  onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                    </Box>


                </Box>
                <StyledDiv style={{ height: "676px", width: "100%", gap: "15px", display: "flex",backgroundImage: `url(${gradiant.default})`, backgroundSize:"cover" }}>
                    <StyledWrapper >
                        <Box style={{ width: "74%", height: "60%", }}>
                            <Typography style={webStyles.typoText}>Find your job!</Typography>
                            <Typography style={{ ...webStyles.smallText,fontFamily:"Manrope", marginBottom: "15px" }}>When you hire a teen through Senior Year, you provide an essential and motivational opportunity for a young person to connect with their broader community and find value in growing their job skills.</Typography>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <Button style={webStyles.boxBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Hire a Teen</Button>
                                <Button style={{ ...webStyles.boxBtn, border: "1px solid #5E70DD", color: "#5E70DD", backgroundColor: "transparent" }}
                                onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}
                                >Get a Job</Button>
                            </Box>
                        </Box>
                    </StyledWrapper>
                    <StyledBox style={{ width: "50%", height: "473px" }}>
                        <img src={landingImg.default}  style={{marginLeft:"60px"}}/>
                    </StyledBox>
                </StyledDiv>
                <StyledmiddleWrap style={{ width: "89.5%", padding: "80px" }}>
                    <Box style={{ width: "100%", display: "flex", gap: "80px" }}>
                        <StyledBox style={{ width: "40%", height: "439px" }}>
                            <img src={bottomImage.default} style={{ width: "100%", height: "439px" }} />
                        </StyledBox>
                        <Box style={{ width: "60%", maxWidth: "784px", height: "356px", padding: "32px 0px 32px 0px", }}>
                            <StyledContent style={{ display: "flex", gap: "45px", marginBottom: "64px" }}>
                                <Box style={webStyles.boxAlign}>
                                    <img src={image1.default} style={webStyles.iconWidth} />
                                    <Typography style={webStyles.imgText}>Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.</Typography>
                                </Box>
                                <Box>
                                    <Box style={webStyles.boxAlign}>
                                        <img src={image2.default} style={webStyles.iconWidth} />
                                        <Typography style={webStyles.imgText}>Id eros pellentesque facilisi id mollis faucibus commodo enim.</Typography>
                                    </Box>
                                </Box>
                            </StyledContent>
                            <StyledContent style={{ display: "flex", gap: "45px" }}>
                                <Box style={webStyles.boxAlign}>
                                    <img src={image3.default} style={webStyles.iconWidth} />
                                    <Typography style={webStyles.imgText}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                                </Box>
                                <Box>
                                    <Box style={webStyles.boxAlign}>
                                        <img src={image4.default} style={webStyles.iconWidth} />
                                        <Typography style={webStyles.imgText}>Curabitur magna cras euismod pharetra, mauris malesuada sit enim, elementum.</Typography>
                                    </Box>
                                </Box>
                            </StyledContent>

                        </Box>
                    </Box>
                </StyledmiddleWrap>
                <StyledFooter 
                style={{
                    width: "89.6%", backgroundColor: "#5E70DD", height: "264px", display: "flex", alignItems: "center",
                    paddingRight: "80px", paddingLeft: "80px", justifyContent: "space-between"
                }}
                >
                    <Box style={{ width:"100%", maxWidth: "457px", height: "203px" }}>
                        <img src={image5.default} style={{ width: "54px", height: "43px", marginBottom: "10px" }} />
                        <Typography style={webStyles.footerText}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                        <Typography style={webStyles.emailText}>CompanyName @ 202X. All rights reserved.</Typography>
                    </Box>

                    <Box style={{height:"203px",width:"600px", marginTop:"60px" }}>
                        <ContactWrapper style={{ display: "flex", gap: "10px" }}>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image6.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Contact us at</Typography>
                                    <Typography style={webStyles.emailText}>senioryear@outlook.com</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image7.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Have a question?</Typography>
                                    <Typography style={webStyles.emailText}>310-437-2766</Typography>
                                </Box>
                            </Box>
                            <Button style={webStyles.signBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                        </ContactWrapper>
                        <Box>
                            <Box style={{ display: "flex", marginTop: "113px", gap:"10px", justifyContent:"flex-end", paddingRight: "8.2%" }}>
                                <Typography style={webStyles.emailText}>Term and Conditions</Typography>
                                <Typography style={webStyles.emailText}>Term and Conditions</Typography>
                            </Box>
                        </Box>

                    </Box>



                </StyledFooter>


            </StyledMainDiv>
        )
    }
}

export const webStyles = {
    typo: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#5E70DD"

    },
    iconWidth: {
        width: "40px",
        height: "36px",
        marginBottom: "16px"
    },
    imgText: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",

    },
    boxAlign: {
        maxWidth: "380px",
        width: "100%",
        height: "114px",
    },
    commonTypo: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left"
    },
    btn: {
        textTransform: "initial" as "initial",
        height: "50px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#5E70DD",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "25.6px",
        color: "#FFFFFF"
    },
    selectBox: {
        maxWidth: "360px",
        width: "100%",
        height: "56px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        marginBottom: "20px"

    },
    typoText: {
        fontFamily: "Caudex",
        fontSize: "70px",
        fontWeight: 700,
        lineHeight: "77px",
        marginBottom: "10px"
    },
    smallText: {
        fontfamily: "Manrope",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "25.2px"
    },
    boxBtn: {
        width: "173px",
        textTransform: "initial" as "initial",
        backgroundColor: "#5E70DD",
        height: "56px",
        borderRadius: "12px",
        border: "2px 0px 0px 0px",
        color: "#FFFFFF"
    },
    footerText: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#FFFFFF",
        marginBottom: "80px"
    },
    emailText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        color: "#FFFFFF"

    },
    smallTypo: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#ECECEC"

    },
    signBtn: {
        width: "125px",
        height: "34px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "initial" as "initial"
    }
}
// Customizable Area End
