export const bottomImage = require("../assets/bottomImage.png");
export const topImage = require("../assets/topImage.png");
export const Logo = require("../assets/Logo.svg");
export const image1 = require("../assets/image1.png");
export const image2 = require("../assets/image2.png");
export const image3 = require("../assets/image3.png");
export const image4 = require("../assets/image4.png");
export const image5 = require("../assets/image5.svg");
export const image6 = require("../assets/image6.png");
export const image7 = require("../assets/image7.png");
export const gradiant = require("../assets/gradiant.png");
export const landingImg = require("../assets/Frame.png");
